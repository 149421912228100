import React from 'react'
import { TextField } from '@mui/material'
import { MuiInputWrapper } from '../../../../../../components/MuiInputWrapper'

type ColdCrankingAmpsInputProps = {
  value: string
  onChange: (value: string) => void
  error?: string
}

export const ColdCrankingAmpsInput = ({ value, onChange, error }: ColdCrankingAmpsInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let intValue = e.target.value.replace(/[^0-9]/g, '')

    if (intValue && parseInt(intValue, 10) > 2000) {
      intValue = '2000'
    }

    onChange(intValue)
  }

  return (
    <MuiInputWrapper error={error} w="100%">
      <TextField
        error={error !== undefined}
        fullWidth
        label="Cold Cranking Amps"
        type="number"
        value={value}
        onChange={handleChange}
        InputProps={{
          style: { background: '#fff' },
        }}
      />
    </MuiInputWrapper>
  )
}
