import React from 'react'
import { TextField } from '@mui/material'

import { MuiInputWrapper } from '../../../../../../components/MuiInputWrapper'

type TireModelInputProps = {
  value: string
  onChange: (value: string) => void
  error?: string
}

export const TireModelInput = ({ value, onChange, error }: TireModelInputProps) => {
  return (
    <MuiInputWrapper error={error}>
      <TextField
        error={error !== undefined}
        fullWidth
        label="Model"
        placeholder="Enter tire model"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          style: {
            background: '#fff',
            width: '100%',
          },
        }}
      />
    </MuiInputWrapper>
  )
}
