import { createTheme } from '@mantine/core'
export const mantineTheme = createTheme({
  shadows: {
    xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
    xxl: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    xxxl: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
  },
  colors: {
    orange: [
      '#fff4e6',
      '#ffe8cc',
      '#ffd8a8',
      '#ffc078',
      '#ffa94d',
      '#ff8c00',
      '#ff6f00',
      '#ff4500',
      '#ff0000',
      '#e60000',
    ],
    success: [
      '#E8F5E9',
      '#C8E6C9',
      '#A5D6A7',
      '#81C784',
      '#66BB6A',
      '#4CAF50',
      '#43A047',
      '#388E3C',
      '#2E7D32',
      '#1B5E20',
    ],
  },
  fontFamily: 'FigTree',
  headings: {
    sizes: {
      h1: {
        fontSize: '36px',
        lineHeight: '44px',
        fontWeight: '600',
      },
    },
  },
  primaryColor: 'orange',
  components: {
    NumberInput: {
      defaultProps: {
        thousandSeparator: ',',
      },
    },
    NumberFormatter: {
      defaultProps: {
        thousandSeparator: true,
      },
    },
    LoadingOverlay: {
      defaultProps: {
        zIndex: 200,
      },
    },
  },
})
