import React, { useState, useEffect, ReactNode } from 'react'
import { LfMeasurementSelect } from './LfMeasurementSelect'
import { LrMeasurementSelect } from './LrMeasurementSelect'
import { LrInsideMeasurementSelect } from './LrInsideMeasurementSelect'
import { RfMeasurementSelect } from './RfMeasurementSelect'
import { RrMeasurementSelect } from './RrMeasurementSelect'
import { RrInsideMeasurementSelect } from './RrInsideMeasurementSelect'
import { Typography } from '@mui/material'
import { APP_FONT } from '../../../../../../constants/app_font'
import Checkbox from '@mui/material/Checkbox'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Group } from '@mantine/core'

const CustomCheckbox = (props) => {
  return (
    <Checkbox
      icon={<CheckBoxOutlineBlank sx={{ color: '#ff6600' }} />}
      checkedIcon={<CheckBox sx={{ color: '#ff6600' }} />}
      {...props}
    />
  )
}

export type TirePositionsFields =
  | 'LF_MEASUREMENT'
  | 'LR_MEASUREMENT'
  | 'RF_MEASUREMENT'
  | 'RR_MEASUREMENT'
  | 'LR_INSIDE_MEASUREMENT'
  | 'RR_INSIDE_MEASUREMENT'

type TirePositionsProps = {
  values: {
    [key in TirePositionsFields]: string
  }
  tirePositions: string[]
  onTirePositionsChange: (tirePositions: string[]) => void
  onChange: (field: TirePositionsFields, value: string) => void
  errors?: {
    LF_MEASUREMENT?: string
    LR_MEASUREMENT?: string
    RF_MEASUREMENT?: string
    RR_MEASUREMENT?: string
    LR_INSIDE_MEASUREMENT?: string
    RR_INSIDE_MEASUREMENT?: string
  }
}

export const TirePositions = ({
  values,
  onChange,
  tirePositions,
  onTirePositionsChange,
  errors,
}: TirePositionsProps) => {
  const [selectedTires, setSelectedTires] = useState<Set<string>>(new Set(tirePositions))

  useEffect(() => {
    onTirePositionsChange(Array.from(selectedTires))
  }, [selectedTires, onTirePositionsChange])

  const toggleSelectedTires = (tire: string) => {
    const updatedTires = new Set(selectedTires)
    if (updatedTires.has(tire)) {
      updatedTires.delete(tire)
      onChange(`${tire}_MEASUREMENT` as TirePositionsFields, '')
    } else {
      updatedTires.add(tire)
    }
    setSelectedTires(updatedTires)
  }

  const isTireSelected = (tire: string) => {
    return selectedTires.has(tire)
  }

  return (
    <div>
      <Typography
        sx={{
          fontFamily: APP_FONT,
          marginRight: '8px',
          fontSize: '14px',
          marginBottom: '16px',
        }}
      >
        Please select tires that need to be replaced and their current tread depths:
      </Typography>
      <Group mb={10} align="start">
        <TireSelectRowWrapper>
          <TireSelectRow
            checked={isTireSelected('LF')}
            onChange={() => toggleSelectedTires('LF')}
            tireTitle="LF"
          >
            <LfMeasurementSelect
              disabled={!isTireSelected('LF')}
              value={values.LF_MEASUREMENT}
              onChange={(value) => onChange('LF_MEASUREMENT', value)}
              error={errors?.LF_MEASUREMENT}
            />
          </TireSelectRow>
        </TireSelectRowWrapper>
        <TireSelectRowWrapper>
          <TireSelectRow
            checked={isTireSelected('LR')}
            onChange={() => toggleSelectedTires('LR')}
            tireTitle="LR"
          >
            <LrMeasurementSelect
              disabled={!isTireSelected('LR')}
              value={values.LR_MEASUREMENT}
              onChange={(value) => onChange('LR_MEASUREMENT', value)}
              error={errors?.LR_MEASUREMENT}
            />
          </TireSelectRow>
        </TireSelectRowWrapper>
        <TireSelectRowWrapper>
          <TireSelectRow
            checked={isTireSelected('LR_INSIDE')}
            onChange={() => toggleSelectedTires('LR_INSIDE')}
            tireTitle="LR Inside"
          >
            <LrInsideMeasurementSelect
              disabled={!isTireSelected('LR_INSIDE')}
              value={values.LR_INSIDE_MEASUREMENT}
              onChange={(value) => onChange('LR_INSIDE_MEASUREMENT', value)}
              error={errors?.LR_INSIDE_MEASUREMENT}
            />
          </TireSelectRow>
        </TireSelectRowWrapper>
      </Group>
      <Group mb={10} align="start">
        <TireSelectRowWrapper>
          <TireSelectRow
            checked={isTireSelected('RF')}
            onChange={() => toggleSelectedTires('RF')}
            tireTitle="RF"
          >
            <RfMeasurementSelect
              disabled={!isTireSelected('RF')}
              value={values.RF_MEASUREMENT}
              onChange={(value) => onChange('RF_MEASUREMENT', value)}
              error={errors?.RF_MEASUREMENT}
            />
          </TireSelectRow>
        </TireSelectRowWrapper>
        <TireSelectRowWrapper>
          <TireSelectRow
            checked={isTireSelected('RR')}
            onChange={() => toggleSelectedTires('RR')}
            tireTitle="RR"
          >
            <RrMeasurementSelect
              disabled={!isTireSelected('RR')}
              value={values.RR_MEASUREMENT}
              onChange={(value) => onChange('RR_MEASUREMENT', value)}
              error={errors?.RR_MEASUREMENT}
            />
          </TireSelectRow>
        </TireSelectRowWrapper>
        <TireSelectRowWrapper>
          <TireSelectRow
            checked={isTireSelected('RR_INSIDE')}
            onChange={() => toggleSelectedTires('RR_INSIDE')}
            tireTitle="RR Inside"
          >
            <RrInsideMeasurementSelect
              disabled={!isTireSelected('RR_INSIDE')}
              value={values.RR_INSIDE_MEASUREMENT}
              onChange={(value) => onChange('RR_INSIDE_MEASUREMENT', value)}
              error={errors?.RR_INSIDE_MEASUREMENT}
            />
          </TireSelectRow>
        </TireSelectRowWrapper>
      </Group>
    </div>
  )
}

const TireSelectRowWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Group mb={10} align="start" flex={1} wrap="nowrap">
      {children}
    </Group>
  )
}

const TireSelectRow = ({
  checked,
  onChange,
  children,
  tireTitle,
}: {
  checked: boolean
  onChange: () => void
  children: ReactNode
  tireTitle: string
}) => {
  return (
    <>
      <Group align="center" wrap="nowrap" gap={8}>
        <CustomCheckbox checked={checked} onChange={onChange} />
        <Typography
          sx={{
            fontFamily: APP_FONT,
            marginRight: '8px',
            fontSize: '15px',
          }}
        >
          {tireTitle}
        </Typography>
      </Group>
      {children}
    </>
  )
}
