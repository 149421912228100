import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import { Col, Container, Row } from 'react-grid-system'
import { AutoSizer } from 'react-virtualized'
import { APP_FONT } from '../../constants/app_font'
import { deleteImages, listImages, markAsCover, uploadImages } from '../../redux/actions/images'
import { useAppDispatch } from '../../redux/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { compressImage } from '../../utils/helper'

const BorderWrapper = styled.div`
  border: solid 1px #e0e0e0;
  border-radius: 10px;
  padding: 24px;
  position: relative;
  overflow-y: auto;
  height: 60vh;
  width: 100%;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
`

const ImageWrapper = styled.div`
  position: relative;
  border: solid 1px #e0e0e0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;
  background: #fff;

  img {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    object-fit: contain;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  &.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  /* (Optional) Apply a "closed-hand" cursor during drag operation. */

  &.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`

const CoverPhotoBlock = styled.div`
  border: solid 1px #e0e0e0;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 20px;
  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
`

type CouldImg = {
  id?: string
  thumbnail_url?: string
  url?: string
  order_index?: number
}

export type FileAndURL = File & { url: string } & CouldImg

const Image = ({
  file,
  draggable,
  onDrag,
  onDelete,
  onSetCover,
}: {
  file: FileAndURL
  draggable?: boolean
  onDrag?: any
  onDelete: () => void
  onSetCover: () => void
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [coverOpen, setCoverOpen] = useState(false)

  const onDeleteClose = () => {
    setDeleteOpen(false)
  }

  const onDeleteOpen = () => setDeleteOpen(true)

  const onCoverClose = () => {
    setCoverOpen(false)
  }

  const onCoverOpen = () => setCoverOpen(true)

  const confirmDelete = () => {
    onDeleteClose()
    onDelete()
  }

  const setAsCover = () => {
    onSetCover()
    onCoverClose()
  }

  return (
    <>
      <ImageWrapper className={draggable ? 'grabbable' : ''} draggable={draggable} onDrag={onDrag}>
        <img src={file.url} />
        <Box position="absolute" top="5px" right="5px">
          <Typography>{file.id ? 'Uploaded' : 'Not uploaded'}</Typography>
        </Box>
        <Button
          onClick={onDeleteOpen}
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          Delete
        </Button>
        <Button
          onClick={onCoverOpen}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
          }}
        >
          Set as cover
        </Button>
      </ImageWrapper>
      <Dialog
        open={deleteOpen}
        onClose={onDeleteClose}
        aria-labelledby="alert-dialog-title-delete"
        aria-describedby="alert-dialog-description-delete"
      >
        <DialogTitle id="alert-dialog-title-delete">Delete image?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-delete">
            Are you sure you want to delete this image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeleteClose}>Cancel</Button>
          <Button onClick={confirmDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={coverOpen}
        onClose={onCoverClose}
        aria-labelledby="alert-dialog-title-cover"
        aria-describedby="alert-dialog-description-cover"
      >
        <DialogTitle id="alert-dialog-title-cover">Set as cover?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cover">
            Are you sure you want to set this image as cover?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCoverClose}>Cancel</Button>
          <Button onClick={setAsCover} autoFocus>
            Set as cover
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const DragEnter = () => {
  const dispatch = useAppDispatch()
  const providerBranchId = localStorage.getItem('branchId')
  const [display, setDisplay] = useState('none')
  const [isLoading, setLoading] = useState(false)
  const [images, setImages] = useState<FileAndURL[]>([])
  const [coverImage, setCoverImage] = useState<FileAndURL>()

  const getImages = async () => {
    if (providerBranchId) {
      await dispatch(listImages(providerBranchId))
        .then(unwrapResult)
        .then((data: any) => {
          setImages(data.images)
          setCoverImage(data.cover_image)
          setLoading(false)
        })
        .catch((logoErr: any) => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    getImages()
  }, [])

  useEffect(() => {
    if (images.length === 0) {
      setDisplay('block')
    } else {
      setDisplay('none')
    }
  }, [images])

  const uploadSelectedImages = () => {
    if (providerBranchId) {
      setLoading(true)
      dispatch(uploadImages({ providerBranchId, files: images }))
        .then(unwrapResult)
        .then(() => {
          getImages()
          setLoading(false)
        })
        .catch((logoErr: any) => {
          setLoading(false)
        })
    }
  }

  const appendImages = async (files: FileAndURL[]) => {
    const allowedTypes = ['image/jpeg', 'image/png']
    const areAllowed = Array.from(files).every((f) => allowedTypes.includes(f.type))
    if (areAllowed) {
      const newImages = [...images]
      for (const file of files) {
        const compressedFile = await compressImage(file)
        if (compressedFile) {
          newImages.push(compressedFile)
        }
      }
      setImages(newImages)
    } else {
      toast.warning('Only png and jpeg images are allowed')
    }
  }

  const onDragOver = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    setDisplay('block')
  }

  const onDragEnter = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onDragLeave = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    setDisplay('none')
  }

  const onDrop = async (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    // @ts-ignore
    Array.from(e.dataTransfer.files).forEach((file: File, index: number) => {
      const url = (window.URL || window.webkitURL).createObjectURL(file)
      // @ts-ignore
      e.dataTransfer.files[index].url = url
    })
    appendImages(e.dataTransfer.files)
    setDisplay('none')
  }

  const onDelete = async (index: number) => {
    const image = images[index]
    if (image.id && providerBranchId) {
      setLoading(true)
      await dispatch(deleteImages({ providerBranchId, imageIds: [image.id] }))
        .then(unwrapResult)
        .then(() => {
          setLoading(false)
        })
        .catch((logoErr: any) => {
          setLoading(false)
        })
    }
    const newImages = [...images]
    newImages.splice(index, 1)
    setImages(newImages)
  }

  const onSetCover = async (index: number) => {
    const image = images[index]
    if (image.id && providerBranchId) {
      setLoading(true)
      await dispatch(markAsCover({ providerBranchId, imageId: image.id }))
        .then(unwrapResult)
        .then(() => {
          setCoverImage(image)
          getImages()
          setLoading(false)
        })
        .catch((logoErr: any) => {
          setLoading(false)
        })
    }
  }

  return (
    <Box
      component="main"
      sx={{
        py: 5,
        width: '100%',
      }}
    >
      <Box sx={{ mr: 5 }}>
        <Typography fontWeight={600} fontFamily={APP_FONT} fontSize={16} color={'#000000'}>
          Cover photo
        </Typography>
        <Typography fontWeight={400} fontFamily={APP_FONT} fontSize={14} color={'black'} mb={3}>
          Displayed as the background for your logo
          <br />
          Displayed to customers on your Carma shop page
        </Typography>
        <Box sx={{ width: '50%' }}>
          {coverImage && (
            <CoverPhotoBlock>
              <img src={coverImage.url} alt="Cover" />
            </CoverPhotoBlock>
          )}
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ mr: 5 }}>
            <Typography fontWeight={600} fontFamily={APP_FONT} fontSize={16} color={'#000000'}>
              Shop gallery photos
            </Typography>
            <Typography
              fontWeight={400}
              fontFamily={APP_FONT}
              fontSize={14}
              color={'black'}
              mb={3}
            >
              Displayed to customers on your Carma shop page
            </Typography>
          </Box>

          <Button
            sx={{
              borderRadius: 10,
              backgroundColor: '#39C815',
              fontSize: 16,
              fontWeight: 700,
              textTransform: 'none',
              marginBottom: 5,
              marginTop: 2,
              fontFamily: 'Figtree',
              color: '#FFFFFF',
            }}
            variant="contained"
            onClick={uploadSelectedImages}
          >
            Upload All Photos
          </Button>
        </Box>
        <BorderWrapper
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          style={{
            flex: 1,
          }}
          onDragLeave={onDragLeave}
        >
          <Wrapper style={{ display }}>
            <Box
              width="100%"
              height="100%"
              display="flex"
              color="#FF6600"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Drag and drop files here</Typography>
            </Box>
          </Wrapper>
          <AutoSizer>
            {({ height, width }) => (
              <Container style={{ height, width }} fluid>
                <Row>
                  {images.map((img, i) => (
                    <Col sm={4} key={i}>
                      <Image
                        file={img}
                        onDelete={() => onDelete(i)}
                        onSetCover={() => onSetCover(i)}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </AutoSizer>
        </BorderWrapper>
      </Box>

      {isLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  )
}
