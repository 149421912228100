import React from 'react'
import styled from 'styled-components'
import { TireBrandSelect } from './TireBrandSelect'
import { TireModelInput } from './TireModelInput'
import { TireWarrantyMilesInput } from './TireWarrantyMilesInput'
import { Typography } from '@mui/material'
import { APP_FONT } from '../../../../../../constants/app_font'

type ChangedTireInfo =
  | { field: 'TIRE_BRAND'; value: string }
  | { field: 'TIRE_MODEL'; value: string }
  | { field: 'WARRANTY'; value: number | string }

type TireInfoProps = {
  values: {
    TIRE_BRAND: string
    TIRE_MODEL: string
    WARRANTY: number
  }
  onChange: (changedTireInfo: ChangedTireInfo) => void
  errors?: {
    TIRE_BRAND?: string
    TIRE_MODEL?: string
    WARRANTY?: string
  }
}

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const TireInfo = ({ values, onChange, errors }: TireInfoProps) => {
  return (
    <div>
      <Typography
        sx={{
          fontFamily: APP_FONT,
          fontSize: '14px',
          marginTop: '16px',
          marginBottom: '16px',
          color: 'black',
          fontWeight: 'bold',
        }}
      >
        Provide the brand, model and warranty of the tires you offer:
      </Typography>
      <Row>
        <div style={{ flex: '1' }}>
          <TireBrandSelect
            error={errors?.TIRE_BRAND}
            value={values.TIRE_BRAND}
            onChange={(value) => {
              onChange({ field: 'TIRE_BRAND', value: value })
            }}
          />
        </div>
        <div style={{ flex: '1' }}>
          <TireModelInput
            error={errors?.TIRE_MODEL}
            value={values.TIRE_MODEL}
            onChange={(value) => onChange({ field: 'TIRE_MODEL', value: value })}
          />
        </div>
        <div style={{ flex: '1' }}>
          <TireWarrantyMilesInput
            error={errors?.WARRANTY}
            value={values.WARRANTY}
            onChange={(value) => onChange({ field: 'WARRANTY', value: value })}
          />
        </div>
      </Row>
    </div>
  )
}
