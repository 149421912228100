import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Box as MaterialBox,
  TextField,
  Divider,
  Stack,
  Alert,
  CircularProgress,
  Backdrop,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { submitTechnicalApproval, uploadPdf } from '../../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import ModalTableHead from './../components/table_head'
import 'react-datepicker/dist/react-datepicker.css'
import { APP_FONT } from '../../../../../constants/app_font'
import {
  fullGeneralDiagnosticService,
  Offer,
  ProviderRequestServiceType,
} from '../../../../../models/offer_new'
import FilePreview from './../components/file_preview'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as DownloadIcon } from '../../../../../assets/images/download.svg'
import { ReactComponent as PdfDownloadIcon } from '../../../../../assets/images/pdf_download.svg'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import { ReactComponent as Close } from '../../../../../assets/images/close.svg'
import { RecServiceBlock } from '../components/RecServiceBlock'
import { Service } from './../inspection_upload/inspection_upload'
import {
  GetServiceId,
  RecommendedService,
  RecommendedServicePart,
  updateServicesWithExistingImages,
} from './../service.model'
import { ReactComponent as PlusIcon } from '../../../../../assets/images/plus_white.svg'
import {
  flattenServiceFormErrors,
  FormError,
  ServiceFormErrors,
  validateRecommendedServiceFields,
} from './../service.validation'
import {
  useListRecServiceImagesByOfferQuery,
  useUploadRecServiceImagesMutation,
} from '../../../../../redux/api/serviceApi/serviceApi'
import { useGetParentService } from '../../../../../hooks/useGetParentService'
import { calculateSubTotalPrice } from '../calculation'
import { statusText } from '../../offer-status'
import { OfferServiceImage } from '../../../../../redux/api/serviceApi/serviceApi.types'
import SubmitShopSupplyFeesAndTax from '../components/SubmitShopSupplyFeesAndTax'
import InvoiceNumberInput from '../../../components/invoice_number'
import { Box, Group } from '@mantine/core'
import RequestedServiceInfoBlock from '../components/RequestedService'
import { ErrorSummary, FlattenedError } from '../../../../../components/ErrorSummary'
import { MuiInputWrapper } from '../../../../../components/MuiInputWrapper'

const MAX_FILE_SIZE = 25 * 1024 * 1024

interface DiagnosticUploadModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  onClose: () => void
  onSubmit: () => void
  offer: Offer
  services: Service[]
  requestedServices: ProviderRequestServiceType[]
}

interface ServicePrices {
  [serviceId: string]: string
}

const DiagnosticUploadModal: React.FC<DiagnosticUploadModalProps> = ({
  open,
  onClose,
  onSubmit,
  offer,
  services,
  requestedServices,
}) => {
  const onCloseWithReason = (event, reason) => {
    if (reason === "backdropClick") {
      // Prevent closing on backdrop click
      return;
    }

    onClose()
  }
  const dispatch = useDispatch()
  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'>(
    'IDLE'
  )
  const navigate = useNavigate()
  const [recServices, setRecServices] = useState<RecommendedService[]>([{
    id: '',
    name: '',
    type: '',
    types: [],
    price: 0,
    serviceType: '',
    labor_hours: 0,
    parts: [],
    imageFiles: [],
    uploadedImageFiles: [],
    additional_data: null,
    labor_price: 0,
  }])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [diagnosticFeedbackFile, setDiagnosticFeedbackFile] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showAdditionalServices, setShowAdditionalServices] = useState(false)
  const [diagnosticResult, setDiagnosticResult] = useState<string | null>(
    offer.diagnostic_result ?? ''
  )
  const [subtotalRequestedServices, setSubtotalRequestedServices] = useState<string>('')
  const [enteringShopSupplyAndTaxEntry, setEnteringShopSupplyAndTaxEntry] = useState(false)
  const [invoiceNumber, setInvoiceNumber] = useState<string>(offer.invoice_number || '')

  const handleUploadClick = () => {
    fileInputRef.current?.click()
  }
  const handleServicePartChange = (
    serviceIndex: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === serviceIndex) {
          return {
            ...service,
            parts: service.parts.map((part, pIndex) => {
              if (pIndex === partIndex) {
                let newValue: any = value

                if (field === 'quantity') {
                  const inputValue = newValue.replace(/[^0-9]/g, '')
                  if (inputValue === '') {
                    newValue = '0'
                  } else {
                    const numberValue = parseInt(inputValue, 10)
                    newValue = numberValue
                  }
                } else if (field === 'price_per_unit') {
                  const input = value.replace(/[^0-9]/g, '')

                  const number = parseInt(input, 10) / 100

                  newValue = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                    .format(number)
                    .slice(1)
                    .replace(/,/g, '')
                }

                return { ...part, [field]: newValue }
              }
              return part
            }),
          }
        }
        return service
      })
    )
  }
  const {
    data: existingFilesResponse,
    refetch: refetchExistingFiles,
    error,
  } = useListRecServiceImagesByOfferQuery({
    offer_id: offer.id,
  })

  useEffect(() => {
    if (error) {
      console.error('Error fetching existing files:', error)
      setAlertOpen(true)
      setAlertText('Something went wrong fetching files! Please try again')
    }
  }, [error])

  useEffect(() => {
    if (existingFilesResponse) {
      const existingFiles = existingFilesResponse.items
      setRecServices((currentRecServices) =>
        currentRecServices.map((recService) => {
          const existingServiceImages = existingFiles.filter(
            (file: OfferServiceImage) =>
              file.service_id === GetServiceId(recService) ||
              file.offer_service_id === recService.offer_service_id
          )
          return {
            ...recService,
            uploadedImageFiles: existingServiceImages,
          }
        })
      )
    }
  }, [existingFilesResponse])

  const { isSuccess, getParentService } = useGetParentService()

  useEffect(() => {
    if (isSuccess) {
      const value = offer?.services
        .filter((service) => !service.is_approved)
        .map((service) => {
          const serviceId = service.service.id
          let parentService = getParentService(serviceId)
          if (!services.some((s) => s.id === parentService?.id)) {
            parentService = null
          }
          const matchingService = services.find(
            (s) => s.id === service.service.parent?.id || s.id === service.service.id
          )

          if (matchingService) {
            const formattedNumber = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
              .format(parseFloat(service.price))
              .slice(1)

            setServicePrices((prevPrices) => ({
              ...prevPrices,
              [`${serviceId}_${service.service.id}`]: formattedNumber,
            }))

            const newRecService: RecommendedService = {
              id: parentService?.id ?? service.service.id,
              name: parentService?.name ?? service.service.name,
              type: parentService?.child?.id ?? null,
              types: parentService?.children ?? [],
              price: parseFloat(service.price),
              serviceType: service.service.type,
              parts:
                service.parts.map((part) => ({
                  name: part.name,
                  quantity: part.quantity,
                  price_per_unit: part.price_per_unit,
                  number: part.number,
                })) || [],
              labor_hours: service.labor_hours,
              labor_price: (offer.labor_rate ?? 0) * service.labor_hours,
              imageFiles: [],
              uploadedImageFiles: [],
              additional_data: service.additional_data,
              offer_service_id: service.id,
              ...service.additional_data,
            }

            const existingFiles = existingFilesResponse?.items ?? []

            const existingServiceImages = existingFiles.filter(
              (file: OfferServiceImage) =>
                file.service_id === service.service.id ||
                file.offer_service_id === GetServiceId(newRecService)
            )

            newRecService.uploadedImageFiles = existingServiceImages

            return newRecService
          }
          return null
        })
        .filter((service) => service !== null)

      setRecServices((prev) => {
        if (prev.length === 0) {
          return value
        }
        return prev
      })
    }
  }, [
    offer.services,
    services,
    isSuccess,
    getParentService,
    offer.labor_rate,
    existingFilesResponse,
  ])

  useEffect(() => {
    const newTotal = calculateSubTotalPrice(recServices, offer.labor_rate ?? 0)
    const formattedTotal = newTotal.toFixed(2)
    setFee(formattedTotal)
  }, [servicePrices, recServices, offer.services, offer.labor_rate])

  useEffect(() => {
    const calculateSubtotalRequestedServices = () => {
      const laborCost = offer.services
        .filter((service) => service.is_approved)
        .reduce((total, service) => total + (service.price ? parseFloat(service.price) : 0), 0)

      const subtotal =
        laborCost + (parseFloat(offer.tax) || 0) + (parseFloat(offer.supply_fee) || 0)
      setSubtotalRequestedServices(subtotal.toFixed(2))
    }

    calculateSubtotalRequestedServices()
  }, [offer.services, offer.labor_rate, offer.tax, offer.supply_fee])

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees?.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax?.replace(/,/g, '')) || 0
      let requestedServicesTotal = offer.services
        .filter((service) => service.is_approved)
        .reduce((total, service) => {
          return total + sanitizeNumber(service.price)
        }, 0)

      const recommendedServicesTotal = calculateSubTotalPrice(recServices, offer.labor_rate ?? 0)

      requestedServicesTotal = Math.ceil(requestedServicesTotal * 100) / 100

      return (recommendedServicesTotal + fees + taxes + requestedServicesTotal).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [recServices, servicePrices, shopSupplyFees, fee, tax, offer.services, offer.labor_rate])

  useEffect(() => {
    if (offer?.files) {
      const feedbackFile = offer.files.find((file) => file.type === 'DIAGNOSTIC_FEEDBACK')
      setDiagnosticFeedbackFile(feedbackFile || null)
      setShowAdditionalServices(true)
    }
    setIsLoading(false)
  }, [offer])

  const handleFileSelect = (selectedFile: File) => {
    if (selectedFile.size > MAX_FILE_SIZE) {
      setAlertOpen(true)
      setAlertText('File is too large. Maximum size is 10MB.')
      return
    }
    setFile(selectedFile)
    setUploadStatus('IDLE')
  }

  const handleSubmit = async () => {
    // TODO: why do we need to check this?
    const filteredServices = recServices.filter((service) => service.serviceType !== 'DIAGNOSIS')

    setAlertOpen(false)

    let formattedServices: any[] = []
    if (showAdditionalServices) {
      formattedServices = filteredServices.map((service) => {
        const additionalData = service.additional_data
          ? Object.keys(service.additional_data).reduce((acc, key) => {
              // @ts-ignore
              if (service.additional_data[key] && service.additional_data[key] !== '') {
                // @ts-ignore
                acc[key] = service.additional_data[key]
              }
              const numberFieldsSet = new Set([
                'COLD_CRANKING_AMPS',
                'FACTORY_COLD_CRANKING_AMPS',
                'WARRANTY',
              ])
              if (numberFieldsSet.has(key)) {
                // @ts-ignore
                acc[key] = Number(service.additional_data[key])
              }
              return acc
            }, {})
          : null

        return {
          service_id: service.type || service.id,
          labor_hours: Number(service.labor_hours),
          parts: service.parts?.map((part) => ({
            name: part.name,
            quantity: Number(part.quantity),
            price_per_unit: Number(part.price_per_unit),
            number: part.number,
          })),
          additional_data: additionalData,
          price: 0,
        }
      })
    }

    const payload = {
      supply_fee: parseFloat(shopSupplyFees?.replace(/[^0-9.-]+/g, '')) || 0,
      tax: parseFloat(tax?.replace(/[^0-9.-]+/g, '')) || 0,
      services: formattedServices,
      invoice_number: invoiceNumber,
      ...(diagnosticResult && { diagnostic_result: diagnosticResult }),
    }

    setIsLoading(true)

    try {
      await Promise.all(
        recServices.map(async (service) => {
          if (service.imageFiles && service.imageFiles.length > 0) {
            const res = await uploadServiceImages({
              offer_id: offer.id,
              service_id: service.type === null || service.type === '' ? service.id : service.type,
              images: service.imageFiles,
            })
            if (res.error) {
              throw res.error
            }
          }
          return
        })
      )
    } catch (uploadError) {
      let errorMessage = uploadError
      if (uploadError && typeof uploadError === 'object' && 'data' in uploadError) {
        if (
          uploadError.data &&
          typeof uploadError.data === 'object' &&
          'message' in uploadError.data
        ) {
          errorMessage = uploadError.data.message
        }
      }
      console.error('Error uploading file:', errorMessage)
      setIsLoading(false)
      setAlertOpen(true)
      setAlertText(`${JSON.stringify(errorMessage)}`)
      return
    }
    dispatch<any>(submitTechnicalApproval({ offerId: offer.id, body: payload }))
      .then(unwrapResult)
      .then(() => {
        setIsLoading(false)
        onSubmit()
        navigate(0)
      })
      .catch((submitError) => {
        console.error('Error submitting for technician approval:', submitError)
        setIsLoading(false)
        setAlertOpen(true)
        setAlertText(`${JSON.stringify(submitError)}`)
      })
  }

  const handleUpload = async () => {
    await handleUploadInspection()
    await handleSubmit()
  }

  const handleUploadInspection = async () => {
    if (!file || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatus('ERROR')
      return
    }
    setUploadStatus('UPLOADING')
    const formData = new FormData()
    formData.append('file', file)

    try {
      dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'INSPECTION', formData }))
        .then(unwrapResult)
        .then((data: any) => {
          setUploadStatus('SUCCESS')
          handleSubmit()
        })
    } catch (error: any) {
      console.error('Error uploading file:', error)
      setUploadStatus('ERROR')
      setAlertText(error ?? 'ERROR on file upload')
    }
  }

  const [uploadServiceImages] = useUploadRecServiceImagesMutation()

  const uploadFeedback = () => {
    switch (uploadStatus) {
      case 'UPLOADING':
        return <Typography color="primary">Uploading...</Typography>
      case 'SUCCESS':
        return <Typography color="green">Upload successful!</Typography>
      case 'ERROR':
        return <Typography color="error">Upload failed. Try again.</Typography>
      default:
        return null
    }
  }

  const renderDroppableArea = () => (
    <div
      onDragOver={(event) => event.preventDefault()}
      onDrop={(event) => {
        event.preventDefault()
        if (event.dataTransfer.files[0]) {
          handleFileSelect(event.dataTransfer.files[0])
        }
      }}
      style={{
        border: '2px dashed #C4C4C4',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        marginBottom: '16px',
        transition: 'background-color 0.3s',
        backgroundColor: '#FAFAFA',
      }}
    >
      <CloudUploadIcon style={{ fontSize: '50px', color: '#C4C4C4' }} />
      <Typography style={{ fontFamily: APP_FONT, cursor: 'pointer' }}>
        <Button
          variant="text"
          component="span"
          style={{
            textTransform: 'none',
            padding: 0,
            fontSize: '16px',
            fontFamily: APP_FONT,
            lineHeight: '24px',
          }}
          color="warning"
          onClick={handleUploadClick}
        >
          Click to upload{' '}
          <div
            style={{
              color: 'black',
              paddingLeft: '5px',
            }}
          >
            {' '}
            or drag and drop
          </div>
        </Button>
      </Typography>
      <Typography variant="caption" style={{ fontFamily: APP_FONT }}>
        PDF, PNG, JPG, JPEG or HEIC (max. 25 MB)
      </Typography>
      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="file-input"
        type="file"
        accept="application/pdf, image/png, image/jpeg, image/jpg, image/heic"
        onChange={(e) => {
          if (e.target.files?.[0]) {
            handleFileSelect(e.target.files[0])
          }
        }}
      />
      {file && (
        <>
          <Typography
            variant="body2"
            sx={{ fontFamily: APP_FONT, fontWeight: 600, fontSize: '16px', mt: 2 }}
          >{`Selected file: ${file.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
    </div>
  )

  const handleServiceChange = useCallback(
    (
      index: number,
      field: keyof RecommendedService | string,
      value: string | string[] | number
    ) => {
      setRecServices((currentServices) => {
        const updatedServices = currentServices.map((service, idx) => {
          if (idx === index) {
            if (field === 'id') {
              const selectedService = services.find((s) => s.id === value)
              return {
                ...service,
                [field]: value.toString(),
                name: selectedService ? selectedService.name : '',
                type: null,
                types: selectedService ? selectedService.children : [],
                additional_data: {},
              }
            } else if (field === 'type') {
              return {
                ...service,
                [field]: value,
                additional_data: {},
              }
            } else {
              return { ...service, [field]: value }
            }
          }
          return service
        })
        //@ts-ignore
        return updateServicesWithExistingImages(existingFilesResponse?.items ?? [], updatedServices)
      })
    },
    [services, existingFilesResponse]
  )

  const handleAdditionalDataChange = useCallback(
    (index: number, field: string, value: string | string[] | number) => {
      //   changes values in service.additional_data
      //   @ts-ignore
      setRecServices((currentServices) =>
        currentServices.map((service, idx) => {
          if (idx === index) {
            return {
              ...service,
              additional_data: {
                ...service.additional_data,
                [field]: value,
              },
            }
          }
          return service
        })
      )
    },
    []
  )

  const handleAddService = () => {
    const newService: RecommendedService = {
      id: '',
      name: '',
      type: '',
      types: [],
      price: 0,
      serviceType: '',
      labor_hours: 0,
      parts: [],
      imageFiles: [],
      uploadedImageFiles: [],
      additional_data: null,
      labor_price: 0,
    }
    setRecServices((prevServices) => [...prevServices, newService])
  }

  const handleRemoveService = (serviceId: string, serviceType: string | null) => {
    setRecServices((prevServices) =>
      prevServices.filter(
        (service) => `${service.id}_${service.type}` !== `${serviceId}_${serviceType}`
      )
    )
    setServicePrices((prevPrices) => {
      const updatedPrices = { ...prevPrices }
      delete updatedPrices[`${serviceId}_${serviceType}`]
      return updatedPrices
    })
  }

  const handleAddServicePart = (serviceIndex: number) => {
    setRecServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              parts: service.parts
                ? [
                    ...service.parts,
                    {
                      name: '',
                      quantity: 1,
                      price_per_unit: 0,
                      number: '',
                    },
                  ]
                : [],
            }
          : service
      )
    )
  }

  const handleImagesChange = useCallback((files: File[], index: number) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          return {
            ...service,
            imageFiles: files,
          }
        }
        return service
      })
    )
  }, [])

  const handleRemoveServicePart = (serviceIndex: number, partIndex: number) => {
    setRecServices((prevServices) => {
      const updatedServices = [...prevServices]
      updatedServices[serviceIndex].parts.splice(partIndex, 1)
      return updatedServices
    })
  }

  const [isFormValid, setIsFormValid] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  type ServicesErrors = Record<number, ServiceFormErrors>
  type DiagnosticUploadFormErrors = {
    services: ServicesErrors
    diagnostic_result?: FormError
  }
  const [formErrors, setFormErrors] = useState<DiagnosticUploadFormErrors>({ services: [] })
  const visibleFormErrors = useMemo(() => {
    return submitClicked ? formErrors : { services: {} }
  }, [formErrors, submitClicked])
  const flattenedErrors = Object.values(visibleFormErrors.services).reduce(
    (acc, errors, index) =>
      acc.concat(
        flattenServiceFormErrors(errors).map((error) => ({
          message: error.message,
          field: `Service ${index + 1} ${error.field}`,
        }))
      ),
    [] as FlattenedError[]
  )
  if (visibleFormErrors.diagnostic_result) {
    flattenedErrors.unshift({
      message: visibleFormErrors.diagnostic_result.message,
      field: 'Diagnostic results',
    })
  }
  useEffect(() => {
    const areServicesValid = recServices.every(
      (service) => validateRecommendedServiceFields(services, service).isValid
    )
    const errors = recServices.map((service, idx) => {
      const { errors } = validateRecommendedServiceFields(services, service)
      return { index: idx, errors: errors }
    })
    const newErrors: DiagnosticUploadFormErrors = { services: {} }
    let newIsValid = areServicesValid
    errors.forEach(({ index, errors: serviceErrors }) => {
      if (serviceErrors) {
        newErrors.services[index] = serviceErrors
      }
    })
    if (!diagnosticResult) {
      newErrors.diagnostic_result = { message: 'Please provide detailed diagnostic results' }
      newIsValid = false
    } else if (diagnosticResult.length < 5) {
      newErrors.diagnostic_result = {
        message: 'Diagnostic results must be at least 5 characters long',
      }
      newIsValid = false
    }
    setFormErrors(newErrors)
    setIsFormValid(newIsValid)
  }, [recServices, offer.labor_rate, services, diagnosticResult])

  const renderServiceBlocks = useCallback(
    () => (
      <>
        {recServices.map((service, index) => {
          const isFullGeneralDiagnostic = service.name === 'Full general diagnostic'

          return (
            <RecServiceBlock
              service={service}
              recServices={recServices}
              key={`${service.id}_${service.type}_${index}`}
              index={index}
              services={services}
              requestedServices={requestedServices}
              servicePrices={servicePrices}
              offer={offer}
              handleServiceChange={handleServiceChange}
              handleServicePartChange={handleServicePartChange}
              handleRemoveService={handleRemoveService}
              handleAddServicePart={handleAddServicePart}
              handleImagesChange={handleImagesChange}
              refetchImages={refetchExistingFiles}
              handleRemoveServicePart={handleRemoveServicePart}
              handleAdditionalDataChange={handleAdditionalDataChange}
              disableServiceChange={isFullGeneralDiagnostic}
              errors={visibleFormErrors.services[index]}
            />
          )
        })}
      </>
    ),
    [
      recServices,
      services,
      servicePrices,
      offer,
      handleServiceChange,
      handleAdditionalDataChange,
      handleImagesChange,
      refetchExistingFiles,
      visibleFormErrors,
      requestedServices,
    ]
  )

  const renderDiagnosticFeedbackFile = () => {
    if (!diagnosticFeedbackFile) return null

    return (
      <MaterialBox
        sx={{
          mt: 2,
          mb: 4,
          p: 2,
          border: 1,
          borderColor: '#FFB38C',
          borderRadius: 'borderRadius',
          bgcolor: 'background.paper',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FF6600' }}>
          Carma ASE-Certified Master Technician Feedback
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 2 }}>
          Download below to view feedback, make necessary adjustments to theapproval, and resubmit:
        </Typography>
        <MaterialBox
          sx={{
            width: '80%',
            padding: 2,
            boxShadow: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <MaterialBox sx={{ display: 'flex', alignItems: 'center' }}>
            <PdfDownloadIcon />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {diagnosticFeedbackFile.name} ({diagnosticFeedbackFile.size})
            </Typography>
          </MaterialBox>
          <Button
            color="warning"
            href={diagnosticFeedbackFile.url}
            download
            startIcon={<DownloadIcon />}
            target="_blank"
          />
        </MaterialBox>
      </MaterialBox>
    )
  }

  const handleShopSupplyFeeChange = (newShopSupplyFees: string) => {
    const input = newShopSupplyFees.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (newTax: string) => {
    const input = newTax.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  const offerContainsDiagnosticService = offer.services.some(
    (service) => service.name === 'Full general diagnostic'
  )

  return (
    <Dialog open={open} onClose={(event, reason) => onCloseWithReason(event, reason)} maxWidth="xl" fullWidth>
      {isLoading ? (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2, height: '120vh' }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress
            size={60}
            sx={{
              color: '#FF6600',
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}
          />
        </Backdrop>
      ) : (
        <>
          <MaterialBox>
            <ModalTableHead
              onHide={onClose}
              service_request={{
                slug: offer.consumer_request.slug,
                vehicle: offer.vehicle,
                services: offer.services,
                drop_off_time: offer.drop_off_by,
                is_diagnostic: offer.consumer_request.type === 'DIAGNOSTIC',
                comment: offer.consumer_request.additional_information,
                status_text: statusText(offer),
                offer_amount: 0,
                pick_up_time: offer.pick_up_after,
              }}
            />
          </MaterialBox>
          {alertOpen && <Alert severity="error">{alertText}</Alert>}
          <DialogContent>
            {enteringShopSupplyAndTaxEntry ? (
              <SubmitShopSupplyFeesAndTax
                tax={tax}
                shopSupplyFees={shopSupplyFees}
                handleTaxChange={handleTaxChange}
                handleShopSupplyFeeChange={handleShopSupplyFeeChange}
                requestedServices={[
                  ...(!offerContainsDiagnosticService ? [fullGeneralDiagnosticService] : []),
                  ...offer.services,
                ]}
                recServices={recServices}
                onExit={() => {
                  setEnteringShopSupplyAndTaxEntry(false)
                }}
                onSubmit={handleUpload}
                isValid={isFormValid}
                total={totalFee}
                laborRate={offer.labor_rate || 0}
              />
            ) : (
              <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid item xs={12} md={5} sx={{ borderRight: '1px solid #EAECF0', pr: 2 }}>
                  <Box mb={16}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Your Offer
                    </Typography>
                    <InvoiceNumberInput
                      invoiceNumber={invoiceNumber}
                      onInvoiceNumberChange={setInvoiceNumber}
                    />
                  </Box>
                  {renderDiagnosticFeedbackFile()}
                  {offer?.consumer_request.files.length > 0 ? (
                    <>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: APP_FONT }}>
                        Concern media
                      </Typography>
                      <Typography sx={{ color: 'text.secondary', mb: 2, fontFamily: APP_FONT }}>
                        Photos and videos uploaded by customer to explain concern
                      </Typography>
                      <MaterialBox
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        mb={2}
                      >
                        <FilePreview files={offer?.consumer_request.files || []} />
                      </MaterialBox>
                    </>
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'bold', mb: 1, fontFamily: APP_FONT }}
                    >
                      No media was uploaded by the customer
                    </Typography>
                  )}
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, fontFamily: APP_FONT }}>
                    Requested services
                  </Typography>
                  {offer.services
                    .filter((service) => service.is_approved)
                    .map((service, index) => (
                      <MaterialBox key={`${service.id}_${service.type}_${index}`}>
                        <RequestedServiceInfoBlock requestedService={service} offer={offer} />
                        {index < offer.services.length - 1 && <Divider />}
                      </MaterialBox>
                    ))}
                  <Divider sx={{ my: 2 }} />
                  {offer?.consumer_request?.type === 'DIAGNOSTIC' && (
                    <>
                      <MaterialBox key={`full_general_diagnostic`}>
                        <MaterialBox
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1,
                            mt: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontFamily: APP_FONT,
                              fontWeight: '600',
                              color: 'black',
                              mt: 1,
                            }}
                          >
                            Full general diagnostic
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '20px',
                              fontFamily: APP_FONT,
                              fontWeight: '600',
                              color: 'black',
                            }}
                          >
                            $0.00
                          </Typography>
                        </MaterialBox>
                      </MaterialBox>
                      <Divider sx={{ my: 2 }} />
                    </>
                  )}

                  <MaterialBox
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: 'black',
                        mr: '8px',
                      }}
                    >
                      Shop supply fees:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: 'black',
                      }}
                    >
                      {formatPrice(offer.supply_fee ?? '0.00')}
                    </Typography>
                  </MaterialBox>
                  <MaterialBox
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: 'black',
                        mr: '8px',
                      }}
                    >
                      Tax:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: 'black',
                      }}
                    >
                      {formatPrice(offer.tax ?? '0.00')}
                    </Typography>
                  </MaterialBox>
                  <MaterialBox
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '700',
                        color: 'black',
                        mr: '8px',
                      }}
                    >
                      {offer.services.length > 1 ? 'Subtotal:' : 'Total:'}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: APP_FONT,
                        fontWeight: '700',
                        color: '#ff6600',
                      }}
                    >
                      {subtotalRequestedServices
                        ? formatPrice(subtotalRequestedServices)
                        : offer.total_fee}
                    </Typography>
                  </MaterialBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  justifyContent="flex-start"
                  direction="column"
                  sx={{
                    fontFamily: APP_FONT,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ fontFamily: APP_FONT, fontWeight: 'bold', mb: 1, color: '#FF6600' }}
                  >
                    Diagnostic results
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: '10px', fontFamily: APP_FONT, flexGrow: 0 }}
                  >
                    <Typography sx={{ marginBottom: 1 }}>
                      Please write the <b>detailed diagnostic results</b> to be included in the
                      diagnostic report for the customer.
                    </Typography>
                    <MuiInputWrapper error={visibleFormErrors.diagnostic_result?.message}>
                      <TextField
                        error={visibleFormErrors.diagnostic_result?.message !== undefined}
                        style={{
                          width: '100%',
                        }}
                        placeholder="Detailed diagnostic results..."
                        multiline
                        rows={4}
                        value={diagnosticResult}
                        onChange={(e) => setDiagnosticResult(e.target.value)}
                      />
                    </MuiInputWrapper>
                  </Grid>
                  <Grid item xs={12} sx={{ flexGrow: 1, marginTop: 2 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: APP_FONT,
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: 'black',
                      }}
                    >
                      Vehicle inspection (optional)
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      If you performed a Digital Vehicle Inspection (DVI) or standard inspection
                      checklist, please upload it.
                    </Typography>
                    {renderDroppableArea()}
                    {!showAdditionalServices && (
                      <Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                          <Button
                            variant="contained"
                            color="warning"
                            onClick={() => setShowAdditionalServices(true)}
                            sx={{
                              width: 'fit-content',
                              borderRadius: '20px',
                              textTransform: 'none',
                              fontWeight: 'bold',
                              paddingLeft: '24px',
                              paddingRight: '24px',
                            }}
                          >
                            <PlusIcon
                              style={{ color: '#ed6c02  !important', marginRight: '8px' }}
                            />
                            <span>Recommend additional services</span>
                          </Button>
                        </Stack>
                      </Stack>
                    )}

                    {showAdditionalServices && (
                      <MaterialBox sx={{ flexGrow: 1 }}>
                        <MaterialBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography
                            variant="h5"
                            sx={{
                              fontFamily: APP_FONT,
                              fontWeight: 'bold',
                              mt: 2,
                              color: '#FF6600',
                            }}
                          >
                            Recommended services
                          </Typography>
                          <MaterialBox>
                            <Button
                              variant="contained"
                              onClick={() => setShowAdditionalServices(false)}
                              sx={{
                                width: 'fit-content',
                                borderRadius: '20px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                backgroundColor: '#FAFAFA',
                                mt: 2,
                                color: '#000',
                                '&:hover': {
                                  backgroundColor: '#e0e0e0',
                                },
                                '&:active': {
                                  backgroundColor: '#grey',
                                  color: '#fff',
                                },
                              }}
                            >
                              <Close />{' '}
                              <Typography sx={{ ml: 1, fontFamily: APP_FONT, fontSize: '14px' }}>
                                {' '}
                                Remove recommended services
                              </Typography>
                            </Button>
                          </MaterialBox>
                        </MaterialBox>

                        <Typography
                          sx={{ color: 'text.secondary', marginBottom: 1, fontFamily: APP_FONT }}
                        >
                          List all the services which customer's vehicle needs
                        </Typography>
                        {renderServiceBlocks()}
                        <Group w="100%" justify="center">
                          <Button
                            onClick={handleAddService}
                            variant="contained"
                            color="warning"
                            sx={{ mt: 1, textTransform: 'none' }}
                          >
                            <span style={{ margin: '4px', color: 'white', fontSize: '16px', fontWeight: 600 }}>
                              + Add new service
                            </span>
                          </Button>
                        </Group>
                      </MaterialBox>
                    )}
                  </Grid>
                  <Group justify="center" mt={'50px'}>
                    <Box flex={1} />
                    <Button
                      variant="contained"
                      color="warning"
                      disabled={!isFormValid && submitClicked}
                      onClick={() => {
                        if (isFormValid) {
                          if (recServices.length > 0 && showAdditionalServices === true) {
                            setEnteringShopSupplyAndTaxEntry(true)
                          } else {
                            handleSubmit()
                          }
                        } else {
                          setSubmitClicked(true)
                        }
                      }}
                      sx={{
                        width: 'fit-content',
                        borderRadius: '20px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        paddingLeft: '32px',
                        paddingRight: '32px',
                        fontSize: '18px'
                      }}
                    >
                      {recServices.length > 0 && showAdditionalServices === true
                        ? 'Done, I’m finished adding all services'
                        : 'Submit for technician approval'}
                    </Button>
                    <Group justify="start" flex={1}>
                      <ErrorSummary errors={flattenedErrors} size="lg" />
                    </Group>
                  </Group>
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default DiagnosticUploadModal
