import React from 'react'
import {
  Box,
  Card,
  Center,
  Flex,
  Modal,
  Overlay,
  Stack,
  ThemeIcon,
  ThemeIconProps,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconMoodWrrr } from '@tabler/icons-react'

export const ErrorClickableWrapper = ({
  errorMessage,
  children,
}: {
  children: React.ReactNode
  errorMessage: string
}) => {
  const [opened, { open, close }] = useDisclosure(false)
  return (
    <>
      <Flex
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          open()
        }}
        style={{ cursor: 'pointer' }}
      >
        {children}
      </Flex>
      <Modal
        title="Error"
        opened={opened}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onClose={close}
        size="lg"
      >
        <Box p="md">{errorMessage}</Box>
      </Modal>
    </>
  )
}

export const ErrorOverlay = ({
  error,
  themeIconProps,
  visible,
}: {
  error?: string
  themeIconProps?: ThemeIconProps
  visible?: boolean
}) => {
  const [opened, { open, close }] = useDisclosure(false)
  if (visible === undefined) {
    visible = error !== undefined && error !== null
  }
  if (!visible) {
    return null
  }
  return (
    <Overlay color="#fff">
      <Center h={'100%'} onClick={open} style={{ cursor: 'pointer' }}>
        <Stack align="center">
          <ThemeIcon variant="light" size="lg" {...themeIconProps}>
            <IconMoodWrrr />
          </ThemeIcon>
        </Stack>
      </Center>
      <Modal title="Error" opened={opened} onClose={close} size="lg">
        <Box p="md">{error ? error : null}</Box>
      </Modal>
    </Overlay>
  )
}

export const FullScreenErrorOverlay = ({
  error,
  visible = true,
}: {
  error?: string
  visible?: boolean
}) => {
  if (!visible) {
    return null
  }
  return (
    <Overlay color="#fff">
      <Center h={'100%'}>
        <Stack align="center">
          <Card withBorder p={30}>
            <Title order={2}>Error</Title>
            {error ? error : null}
          </Card>
        </Stack>
      </Center>
    </Overlay>
  )
}
