import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { listValidations } from '../../../../../../redux/api/serviceApi/listValidations'

import { MuiInputWrapper } from '../../../../../../components/MuiInputWrapper'

type RrInsideMeasurementSelectProps = {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  error?: string
}

export const RrInsideMeasurementSelect = ({
  value,
  onChange,
  disabled = false,
  error,
}: RrInsideMeasurementSelectProps) => {
  const items = listValidations.RR_INSIDE_MEASUREMENT.permitted_values

  return (
    <Autocomplete
      disabled={disabled}
      size="small"
      value={value}
      onChange={(event, newValue: any) => onChange(newValue)}
      options={items}
      getOptionLabel={(option) => option}
      getOptionKey={(option) => option}
      renderInput={(params) => (
        <MuiInputWrapper error={error}>
          <TextField
            style={{
              background: disabled ? '#F9FAFB' : '#fff',
            }}
            {...params}
            label="Measurement"
            placeholder="Select or search"
            error={error !== undefined}
          />
        </MuiInputWrapper>
      )}
      isOptionEqualToValue={(option, value) => option === value}
      sx={{ width: '100%' }}
      disableClearable
      freeSolo
    />
  )
}
