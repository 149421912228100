import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
  CircularProgress,
  Backdrop,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useDispatch } from 'react-redux'
import { updatePassword } from '../../redux/actions/user'
import { unwrapResult } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/system'
import { AccountSettings } from '../../models/account_settings'
import {
  fetchAccount,
  fetchVehicleClasses,
  fetchVehicleEngines,
  updateAccountSettings,
  updateWorkingHours,
} from '../../redux/actions/account_settings'
import { APP_FONT } from '../../constants/app_font'
import { ReactComponent as PhoneIcon } from '../../assets/images/phone_icon.svg'
import { ReactComponent as EmailIcon } from '../../assets/images/email_icon.svg'
import { ReactComponent as TimeIcon } from '../../assets/images/time_icon.svg'
import { AppHeader } from '../../components/header/app_header'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as PdfDownloadIcon } from '../../assets/images/download.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { formatPrice } from '../../components/helper/helper'

const drawerWidth = 240

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

interface WorkingHour {
  open: string | null
  close: string | null
  is_working_day: boolean
}

export interface WorkingHours {
  Monday: WorkingHour
  Tuesday: WorkingHour
  Wednesday: WorkingHour
  Thursday: WorkingHour
  Friday: WorkingHour
  Saturday: WorkingHour
  Sunday: WorkingHour
}

const defaultWorkingHours: WorkingHours = {
  Monday: { open: '08:00', close: '17:00', is_working_day: true },
  Tuesday: { open: '08:00', close: '17:00', is_working_day: true },
  Wednesday: { open: '08:00', close: '17:00', is_working_day: true },
  Thursday: { open: '08:00', close: '17:00', is_working_day: true },
  Friday: { open: '08:00', close: '17:00', is_working_day: true },
  Saturday: { open: null, close: null, is_working_day: false },
  Sunday: { open: null, close: null, is_working_day: false },
}

const formatTime = (time: string | null): string => {
  if (!time) return ''
  const [hours, minutes] = time.split(/[: ]/)
  const isPM = time.includes('PM')
  const formattedHours =
    isPM && hours !== '12'
      ? (parseInt(hours) + 12).toString().padStart(2, '0')
      : hours.padStart(2, '0')
  return `${formattedHours}:${minutes}`
}

const Settings: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [initialSettings, setInitialSettings] = useState<AccountSettings | null>(null)
  const [settings, setSettings] = useState<AccountSettings | null>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [reset, setReset] = useState(false)
  const [stripeCompleted, setStripeCompleted] = useState<boolean>(true)
  const [stripeLink, setStripeLink] = useState<string>('')
  const [vehicleClasses, setVehicleClasses] = useState<any[]>([])
  const [vehicleEngines, setVehicleEngines] = useState<any[]>([])
  const [selectedVehicleClasses, setSelectedVehicleClasses] = useState<string[]>([])
  const [selectedVehicleEngines, setSelectedVehicleEngines] = useState<string[]>([])
  const [workingHours, setWorkingHours] = useState<WorkingHours>(defaultWorkingHours)

  const submit: SubmitHandler<any> = async (register: any) => {
    setError('')
    setReset(false)
    setLoading(true)

    const body: any = {
      oldPassword: register.currentPassword,
      newPassword: register.newPassword,
    }

    dispatch<any>(updatePassword(body))
      .then(unwrapResult)
      .then((res: any) => {
        setLoading(false)
        if (res === 'SUCCESS') {
          scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
          setReset(true)
        } else {
          setError(res.message)
          scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
        }
      })
      .catch((err: any) => {
        setLoading(false)
        setError(err)
      })
  }

  const schema = yup.object().shape({
    currentPassword: yup.string().required('Password is required'),
    newPassword: yup
      .string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        'Invalid password format'
      ),
    confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match'),
  })

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const validateField = (fieldName: 'currentPassword' | 'newPassword' | 'confirmPassword') => {
    trigger(fieldName)
  }

  useEffect(() => {
    const cognitoId = localStorage.getItem('cognitoId')
    if (cognitoId === null || cognitoId === undefined) {
      navigate('/')
    } else {
      dispatch<any>(fetchAccount())
        .then(unwrapResult)
        .then(async (settings: any) => {
          if (settings?.stripe_payouts_enabled) {
            setStripeCompleted(true)
          } else {
            setStripeLink(settings?.stripe_link as string)
            setStripeCompleted(false)
            setError(
              'Stripe onboarding not completed. Please verify the details by clicking the "Connect Stripe" link.'
            )
          }
          setSettings({
            email_notification: settings.email_notification,
            text_notification: settings.text_notification,
            disable_notifications_outside_working_hours:
              settings.disable_notifications_outside_working_hours,
            vehicle_classes: settings.vehicle_classes,
            vehicle_engines: settings.vehicle_engines,
            labor_rate: settings.labor_rate,
          })
          setInitialSettings({
            email_notification: settings.email_notification,
            text_notification: settings.text_notification,
            disable_notifications_outside_working_hours:
              settings.disable_notifications_outside_working_hours,
            vehicle_classes: settings.vehicle_classes,
            vehicle_engines: settings.vehicle_engines,
            labor_rate: settings.labor_rate,
          })
          setSelectedVehicleClasses(settings.vehicle_classes)
          setSelectedVehicleEngines(settings.vehicle_engines)
          const formattedWorkingHours: WorkingHours = {
            Monday: {
              ...settings.working_hours.Monday,
              open: formatTime(settings.working_hours.Monday.open),
              close: formatTime(settings.working_hours.Monday.close),
            },
            Tuesday: {
              ...settings.working_hours.Tuesday,
              open: formatTime(settings.working_hours.Tuesday.open),
              close: formatTime(settings.working_hours.Tuesday.close),
            },
            Wednesday: {
              ...settings.working_hours.Wednesday,
              open: formatTime(settings.working_hours.Wednesday.open),
              close: formatTime(settings.working_hours.Wednesday.close),
            },
            Thursday: {
              ...settings.working_hours.Thursday,
              open: formatTime(settings.working_hours.Thursday.open),
              close: formatTime(settings.working_hours.Thursday.close),
            },
            Friday: {
              ...settings.working_hours.Friday,
              open: formatTime(settings.working_hours.Friday.open),
              close: formatTime(settings.working_hours.Friday.close),
            },
            Saturday: {
              ...settings.working_hours.Saturday,
              open: formatTime(settings.working_hours.Saturday.open),
              close: formatTime(settings.working_hours.Saturday.close),
            },
            Sunday: {
              ...settings.working_hours.Sunday,
              open: formatTime(settings.working_hours.Sunday.open),
              close: formatTime(settings.working_hours.Sunday.close),
            },
          }

          setWorkingHours(formattedWorkingHours || defaultWorkingHours)
        })
        .catch((rejectedValueOrSerializedError: any) => {
          console.log('error')
        })
    }
  }, [dispatch, navigate])

  useEffect(() => {
    dispatch<any>(fetchVehicleClasses())
      .then(unwrapResult)
      .then(async (classes: any) => {
        setVehicleClasses(classes.items)
      })
      .catch((error: any) => {
        console.error('Error fetching vehicle classes:', error)
      })
  }, [dispatch])

  useEffect(() => {
    dispatch<any>(fetchVehicleEngines())
      .then(unwrapResult)
      .then(async (engines: any) => {
        setVehicleEngines(engines.items)
      })
      .catch((error: any) => {
        console.error('Error fetching vehicle engines:', error)
      })
  }, [dispatch])

  const handleTextNotificationsChange = () => {
    settings &&
      setSettings({
        ...settings,
        text_notification: !settings.text_notification,
      })
  }

  const handleEmailNotificationsChange = () => {
    settings &&
      setSettings({
        ...settings,
        email_notification: !settings.email_notification,
      })
  }

  const handleAllowNotificationsChange = () => {
    settings &&
      setSettings({
        ...settings,
        disable_notifications_outside_working_hours:
          !settings.disable_notifications_outside_working_hours,
      })
  }

  const handleSettingsChange = () => {
    setLoading(true)
    setError('')
    setReset(false)
    setSuccess(false)
    const updated_settings = {
      text_notification: !!settings?.text_notification,
      email_notification: !!settings?.email_notification,
      vehicle_classes: selectedVehicleClasses,
      vehicle_engines: selectedVehicleEngines,
      ...(settings?.labor_rate !== initialSettings?.labor_rate && {
        labor_rate: parseFloat(settings?.labor_rate?.replace(/,/g, '') ?? '0'),
      }),
    }

    dispatch<any>(updateAccountSettings(updated_settings))
      .then(unwrapResult)
      .then((settings: any) => {
        setLoading(false)
        setSuccess(true)
      })
      .catch((err: any) => {
        setLoading(false)
        setError(err.message || 'An error occurred')
      })
  }

  const handleSaveWorkingHours = () => {
    setLoading(true)
    setError('')
    setReset(false)
    setSuccess(false)

    dispatch<any>(updateWorkingHours(workingHours))
      .then(unwrapResult)
      .then((settings: any) => {
        setLoading(false)
        setSuccess(true)
      })
      .catch((err: any) => {
        setLoading(false)
        setError(err.message || 'An error occurred')
      })
  }

  const errorMessage = () => {
    let errorMessage
    if (!!error) {
      errorMessage = 'Incorrect current password. Please try again!'
    } else if (!!success) {
      errorMessage = 'Settings Updated Successfully!'
    } else if (!!reset) {
      errorMessage = 'Password Updated Successfully!'
    } else {
      errorMessage = ''
    }

    return errorMessage
  }

  const handleStripeConnect = () => {
    window.location.href = stripeLink
  }

  const handleWorkingHoursChange = (
    day: keyof WorkingHours,
    field: keyof WorkingHour,
    value: any
  ) => {
    setWorkingHours((prevHours) => ({
      ...prevHours,
      [day]: { ...prevHours[day], [field]: value },
    }))
  }

  const handleLaborRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100
    const validNumber = isNaN(number) ? 0 : number

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(validNumber)
      .slice(1)

    settings &&
      setSettings({
        ...settings,
        labor_rate: formattedNumber,
      })
  }

  return (
    <Box sx={{ display: 'flex', pt: 10 }} ref={scrollRef}>
      <CssBaseline />
      <AppHeader />
      <Box
        component="main"
        sx={{
          paddingLeft: 6,
          paddingRight: 6,
          paddingTop: 4,
          paddingBottom: 4,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {(!!error || reset) && (
          <Alert
            severity={!!error ? 'error' : 'success'}
            sx={{ marginBottom: 2, fontFamily: 'Figtree' }}
          >
            {error}
          </Alert>
        )}

        {!!success && (
          <Alert severity="success" sx={{ marginBottom: 5, fontFamily: 'Figtree' }}>
            Settings updated successfully!
          </Alert>
        )}

        <Typography fontWeight={700} fontFamily={APP_FONT} fontSize={32} color={'#FF6600'}>
          Settings
        </Typography>
        <Stack sx={{ display: 'flex', py: 2 }}>
          <Typography
            variant="h5"
            sx={{ fontFamily: { APP_FONT }, fontWeight: 'bold', marginBottom: 1 }}
          >
            Preventative Maintenance Inspections (PMI)
          </Typography>
          <a
            href="https://joincarma-images.s3.amazonaws.com/PMI+final.pdf"
            download
            target="_blank"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: '#FF6600',
            }}
            rel="noreferrer"
          >
            <Typography
              fontFamily={APP_FONT}
              fontSize={16}
              color={'#FF6600'}
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: '6px',
              }}
            >
              Click to download the PMI template
            </Typography>
            <PdfDownloadIcon />
          </a>
        </Stack>

        {!settings ? (
          <CircularProgress color="warning" size={80} sx={{ marginTop: 10 }} />
        ) : (
          <Stack direction={'column'} spacing={2}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold' }}
            >
              Notifications
            </Typography>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <EmailIcon height="20" width="20" color="#0000008A" />
              <Stack direction={'row'} alignItems={'center'} spacing={41}>
                <Typography variant="body1" fontFamily={APP_FONT} fontSize={16} fontWeight={400}>
                  Email notifications
                </Typography>

                {settings && (
                  <Switch
                    onChange={handleEmailNotificationsChange}
                    checked={settings?.email_notification}
                    size="small"
                    color="warning"
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <PhoneIcon height="20" width="20" />
              <Stack direction={'row'} alignItems={'center'} spacing={42}>
                <Typography variant="body1" fontFamily={APP_FONT} fontSize={16} fontWeight={400}>
                  Text notifications
                </Typography>
                {settings && (
                  <Switch
                    checked={settings?.text_notification}
                    onChange={handleTextNotificationsChange}
                    size="small"
                    color="warning"
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <TimeIcon height="20" width="20" color="#0000008A" />
              <Stack direction={'row'} alignItems={'center'} spacing={13}>
                <Typography variant="body1" fontFamily={APP_FONT} fontSize={16} fontWeight={400}>
                  Allow notifications outside of 8:00 AM - 5:00 PM
                </Typography>
                {settings && (
                  <Switch
                    checked={settings?.disable_notifications_outside_working_hours}
                    onChange={handleAllowNotificationsChange}
                    size="small"
                    color="warning"
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack sx={{ py: 2 }}>
              <Typography
                variant="h5"
                sx={{  fontWeight: 'bold', marginBottom: 1 }}
              >
                Supported vehicle classes and engines
              </Typography>
              <FormControl sx={{ mt: 2}}>
                <InputLabel id="vehicle-class-label">Vehicle classes</InputLabel>
                <Select
                  labelId="vehicle-class-label"
                  id="vehicle-class-select"
                  multiple
                  value={selectedVehicleClasses}
                  label="Maximum vehicle class"
                  onChange={(event) => setSelectedVehicleClasses(event.target.value as string[])}
                  sx={{
                    width: 400,
                    overflow: 'auto',
                    whiteSpace: 'nowrap',
                  }}
                  renderValue={(selected) =>
                    selected
                      .map((value) => `${vehicleClasses.find((vc) => vc.id === value)?.name}`)
                      .join(', ')
                  }
                >
                  {vehicleClasses?.map((vehicleClass: any) => (
                    <MenuItem key={vehicleClass.id} value={vehicleClass.id}>
                      <Checkbox checked={selectedVehicleClasses.indexOf(vehicleClass.id) > -1} />
                      <ListItemText
                        primary={`${vehicleClass.name}`}
                        secondary={`Weight: ${vehicleClass.weight.from} - ${vehicleClass.weight.to} lbs`}
                      />
                    </MenuItem>
                  ))}
                </Select>
                <p style={{ color: 'grey', fontSize: 12, marginTop: 1 }}>
                  Up to which vehicle class is your shop able to service?
                </p>
              </FormControl>
              <FormControl sx={{ mt: 2}}>
                <InputLabel id="vehicle-engines-label">Supported vehicle engines</InputLabel>
                <Select
                  labelId="vehicle-engines-label"
                  id="vehicle-engines-select"
                  multiple
                  value={selectedVehicleEngines}
                  onChange={(event: any) => {
                    setSelectedVehicleEngines(event.target.value)
                  }}
                  renderValue={(selected) => selected.join(', ')}
                  label="Supported vehicle engines"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                      },
                    },
                  }}
                  sx={{ width: 400 }}
                >
                  {vehicleEngines.map((engine) => (
                    <MenuItem key={engine} value={engine}>
                      <Checkbox checked={selectedVehicleEngines.indexOf(engine) > -1} />
                      <ListItemText primary={engine} />
                    </MenuItem>
                  ))}
                </Select>
                <p style={{ color: 'grey', fontSize: 12, marginTop: 1 }}>
                  Which vehicle engines is your shop able to service?
                </p>
              </FormControl>
              <Button
                sx={{
                  borderRadius: 10,
                  backgroundColor: '#FF6600',
                  fontSize: 16,
                  fontWeight: 700,
                  textTransform: 'none',
                  fontFamily: APP_FONT,
                  color: '#FFFFFF',
                  width: 250,
                }}
                variant="contained"
                onClick={handleSettingsChange}
              >
                Save classes and engines
              </Button>
            </Stack>
            <Stack sx={{ maxWidth: '63%' }}>
              <Typography
                variant="h5"
                sx={{ fontFamily: { APP_FONT }, fontWeight: 'bold', marginBottom: 1 }}
              >
                Labor rate
              </Typography>
              <Typography variant="body1" fontFamily={APP_FONT} fontSize={16} sx={{ mb: 3 }}>
                Please provide your most competitive shop labor rate for large, commercial fleet
                customers. Pricing more competitively significantly increases your chances of
                winning requests. Your work orders will be evaluated for labor times based on the
                labor rate that you provide here. Any changes to your labor rate will only apply to
                any new offers that are are accepted.
              </Typography>
              <TextField
                value={formatPrice(settings.labor_rate || '')}
                onChange={handleLaborRateChange}
                InputProps={{
                  style: { background: '#fff' },
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px' },
                }}
                style={{ width: '200px' }}
              />
              <Button
                sx={{
                  borderRadius: 10,
                  backgroundColor: '#FF6600',
                  fontSize: 16,
                  fontWeight: 700,
                  textTransform: 'none',
                  fontFamily: 'Figtree',
                  color: '#FFFFFF',
                  width: 200,
                  mt: 2,
                }}
                variant="contained"
                onClick={handleSettingsChange}
              >
                Save labor rate
              </Button>
            </Stack>
            <Stack sx={{ maxWidth: '700px', py: 3 }}>
              <Typography
                variant="h5"
                sx={{ fontFamily: { APP_FONT }, fontWeight: 'bold', marginBottom: 1 }}
              >
                Working hours
              </Typography>
              <Stack direction="row" sx={{ marginBottom: 1, alignItems: 'center' }}>
                <Typography sx={{ flex: 1, fontWeight: 'bold', color: 'text.secondary' }}>
                  OPEN
                </Typography>
                <Typography sx={{ flex: 1.5, fontWeight: 'bold', color: 'text.secondary' }}>
                  CLOSE
                </Typography>
              </Stack>
              {daysOfWeek.map((day) => (
                <Box key={day} display="flex" alignItems="center" mb={2}>
                  <TextField
                    type="time"
                    color="warning"
                    value={workingHours[day as keyof WorkingHours]?.open}
                    onChange={(e) =>
                      handleWorkingHoursChange(day as keyof WorkingHours, 'open', e.target.value)
                    }
                    disabled={!workingHours[day as keyof WorkingHours]?.is_working_day}
                    InputLabelProps={{ shrink: true }}
                    sx={{ flex: 1, mr: 2 }}
                  />
                  <TextField
                    type="time"
                    color="warning"
                    value={workingHours[day as keyof WorkingHours]?.close || ''}
                    onChange={(e) =>
                      handleWorkingHoursChange(day as keyof WorkingHours, 'close', e.target.value)
                    }
                    disabled={!workingHours[day as keyof WorkingHours]?.is_working_day}
                    InputLabelProps={{ shrink: true }}
                    sx={{ flex: 1, mr: 2 }}
                  />
                  <Switch
                    color="warning"
                    checked={workingHours[day as keyof WorkingHours]?.is_working_day}
                    onChange={(e) =>
                      handleWorkingHoursChange(
                        day as keyof WorkingHours,
                        'is_working_day',
                        e.target.checked
                      )
                    }
                  />
                  <Typography sx={{ width: '100px' }}>{day}</Typography>
                </Box>
              ))}
              <Button
                sx={{
                  borderRadius: 10,
                  backgroundColor: '#FF6600',
                  fontSize: 16,
                  fontWeight: 700,
                  textTransform: 'none',
                  fontFamily: 'Figtree',
                  color: '#FFFFFF',
                  maxWidth: '220px',
                  mt: 1,
                }}
                variant="contained"
                onClick={handleSaveWorkingHours}
              >
                Save working hours
              </Button>
            </Stack>
            <Stack>
              <Typography
                variant="h5"
                sx={{ fontFamily: { APP_FONT }, fontWeight: 'bold', marginBottom: 1 }}
              >
                Change password
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  width: 'fit-content',
                }}
              >
                <form>
                  <Controller
                    name="currentPassword"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        value={value}
                        onChange={(e) => {
                          onChange(e)
                          validateField('currentPassword')
                        }}
                        type="password"
                        label="Current password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={!!errors.currentPassword}
                        helperText={errors.currentPassword?.message?.toString()}
                        InputLabelProps={{
                          style: { fontFamily: 'Figtree' },
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="newPassword"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        value={value}
                        onChange={(e) => {
                          onChange(e)
                          validateField('newPassword')
                        }}
                        type="password"
                        label="New password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={!!errors.newPassword}
                        helperText={errors.newPassword?.message?.toString()}
                        InputLabelProps={{
                          style: { fontFamily: 'Figtree' },
                        }}
                      />
                    )}
                  />
                  <p style={{ color: 'grey', fontSize: 12, marginTop: 1 }}>
                    (Password must contain at least one letter, one number, and one special
                    character)
                  </p>

                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        value={value}
                        onChange={(e) => {
                          onChange(e)
                          validateField('confirmPassword')
                        }}
                        type="password"
                        label="Confirm new password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message?.toString()}
                        InputLabelProps={{
                          style: { fontFamily: 'Figtree' },
                        }}
                      />
                    )}
                  />

                  <Button
                    sx={{
                      borderRadius: 10,
                      backgroundColor: '#FF6600',
                      fontSize: 16,
                      fontWeight: 700,
                      textTransform: 'none',
                      mt: 2,
                      fontFamily: 'Figtree',
                      color: '#FFFFFF',
                    }}
                    variant="contained"
                    onClick={handleSubmit(submit)}
                  >
                    Update password
                  </Button>

                  {!stripeCompleted && (
                    <>
                      <Typography variant="body1" fontFamily={APP_FONT} fontSize={16} marginTop={8}>
                        We partner with Stripe to deliver safe and secure payments to partner shops,
                        with best-in-class payment security. To receive payments, please continue to
                        create or connect your existing Stripe account to Carma.{' '}
                      </Typography>
                      <Button
                        sx={{
                          borderRadius: 10,
                          backgroundColor: '#FFFFFF',
                          fontSize: 16,
                          fontWeight: 700,
                          textTransform: 'none',
                          marginTop: 3,
                          fontFamily: 'Figtree',
                          color: '#FF6600',
                          border: '1px solid #FF6600',
                          '&:hover': {
                            color: '#FFFFFF',
                            borderColor: '#1976d2',
                          },
                        }}
                        variant="contained"
                        onClick={handleStripeConnect}
                      >
                        Connect Stripe
                        <ArrowForwardIosIcon sx={{ marginLeft: 1, fontSize: 'medium' }} />
                      </Button>
                    </>
                  )}
                </form>
              </Box>
            </Stack>
          </Stack>
        )}
      </Box>
      {isLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, marginTop: 5 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="warning" size={60} />
        </Backdrop>
      )}
    </Box>
  )
}

export default Settings
