import React, { useCallback, useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { useLazyListVehicleBrandsQuery } from '../../../../../../redux/api/vehicleApi/vehicleApi'
import { VehicleBrand } from '../../../../../../redux/api/vehicleApi/vehicleApi.types'

import { ErrorOverlay } from '../../../../../../components/ErrorOverlay'
import { MuiInputWrapper } from '../../../../../../components/MuiInputWrapper'

type TireBrandSelectProps = {
  value: string
  onChange: (value: string) => void
  error?: string
}

export const TireBrandSelect = ({ value, onChange, error }: TireBrandSelectProps) => {
  const [tireBrands, setTireBrands] = useState<VehicleBrand[]>([])
  const [fetchingBrandsError, setFetchingBrandsError] = useState<string | undefined>(error)

  const [listVehicleBrands] = useLazyListVehicleBrandsQuery()

  const fetchAllPages = useCallback(async () => {
    //   page parameter is passed to listVehicleBrands function. The response includes count parameter which is the total number of items in the list. The limit is also passed as 100.
    //   The loop will fetch all pages of the list and add the items to the items array.
    let page = 1
    let count = 0
    let limit = 100
    let items: VehicleBrand[] = []
    do {
      try {
        const response = await listVehicleBrands({ page, limit }).unwrap()
        items = items.concat(response.items)
        count = response.count
        page++
      } catch (error) {
        setFetchingBrandsError('Error fetching tire brands')
        console.error(new Error(JSON.stringify(error)))
        break
      }
    } while (items.length < count)
    setTireBrands(items)
  }, [listVehicleBrands])

  useEffect(() => {
    fetchAllPages()
  }, [fetchAllPages])

  return (
    <MuiInputWrapper error={error} pos="relative">
      <ErrorOverlay error={fetchingBrandsError} />
      <Autocomplete
        value={value ?? ''}
        onChange={(_, newValue) => onChange(newValue)}
        options={tireBrands.map((tireBrand) => tireBrand.name)}
        renderInput={(params) => (
          <TextField
            error={error !== undefined}
            {...params}
            fullWidth
            label="Tire brand"
            placeholder="Select tire brand"
            sx={{
              backgroundColor: '#ffffff',
            }}
          />
        )}
        disableClearable
      />
    </MuiInputWrapper>
  )
}
