import { Button, Modal, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useRef, useEffect } from 'react'
import { APP_FONT } from '../../constants/app_font'
import ModalTableHeadShort from '../../pages/dashboard/tables/modals/components/table_head_short'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  width: 700,
  maxHeight: '90vh',
  overflowY: 'auto',
}

export default function MileageOutModal(props: any) {
  const inputRef = useRef<any>(null)
  const { isOpen, setIsOpen, updateMileage, offer } = props
  const [mileageOut, setMileageOut] = React.useState<any>('')
  const [error, setError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<any>('')

  const handleMileageOutChange = (value: string) => {
    const formattedValue = value
      .replace(/^0+/, '')
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    setMileageOut(formattedValue)
  }

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }, [])
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(!isOpen)}>
      <Box sx={style}>
        <ModalTableHeadShort
          onHide={() => setIsOpen(!isOpen)}
          service_request={{
            slug: offer.slug,
            vehicle: offer.vehicle ?? offer.consumer_request?.vehicle,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '550px',
            margin: '0 auto',
            mb: 5,
            mt: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              fontFamily: APP_FONT,
              textAlign: 'left',
              color: '#FF6600',
            }}
          >
            Enter the Out-Mileage to checkout the vehicle.
          </Typography>

          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              fontFamily: APP_FONT,
              textAlign: 'left',
              color: 'black',
            }}
          >
            This will be printed on your final invoice.
          </Typography>

          <TextField
            inputRef={inputRef}
            sx={{ mt: '15px' }}
            fullWidth
            color="warning"
            label="Mileage"
            variant="outlined"
            focused
            value={mileageOut}
            onChange={(e) => {
              handleMileageOutChange(e.target.value)
            }}
            type="text"
            inputProps={{
              maxLength: 7,
            }}
          />
          {error && (
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: 500,
                fontFamily: APP_FONT,
                color: 'red',
              }}
            >
              {errorMessage}
            </Typography>
          )}
          <Box
            component="span"
            display="flex"
            justifyContent="right"
            marginTop={'22px'}
          >
            <Button
              onClick={() => setIsOpen(false)}
              sx={{
                width: 150,
                height: 40,
                borderRadius: '20px',
                mr: 5,
              }}
              variant="outlined"
              color="warning"
            >
              <Typography
                sx={{
                  color: '#FF6600',
                  fontSize: '18px',
                  fontWeight: 700,
                  fontFamily: APP_FONT,
                }}
              >
                Cancel
              </Typography>
            </Button>
            <Button
              onClick={() => {
                updateMileage(mileageOut)
                setIsOpen(false)
              }}
              sx={{ width: 150, height: 40, borderRadius: '20px' }}
              variant="contained"
              color="warning"
              disabled={mileageOut === ''}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 700,
                  fontFamily: APP_FONT,
                }}
              >
                Complete
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
