import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
  Stack,
  Divider,
  TextField,
  InputAdornment,
  Alert,
  AlertTitle,
} from '@mui/material'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { updateOfferRevisedTax } from '../../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import ModalTableHead from './../components/table_head'
import FilePreview from './../components/file_preview'
import { calculateOfferAmount, Offer } from '../../../../../models/offer_new'
import { APP_FONT } from '../../../../../constants/app_font'
import { ReactComponent as PdfIcon } from '../../../../../assets/images/pdf_download.svg'
import { formatPrice } from '../../../../../components/helper/helper'
import { statusText } from '../../offer-status'

interface InvoiceUploadModalProps {
  setOpen: (arg: boolean) => void
  open: boolean
  onClose: () => void
  onSubmit: () => void
  offer: Offer
}

const InvoiceUploadModal: React.FC<InvoiceUploadModalProps> = ({
  setOpen,
  open,
  onClose,
  onSubmit,
  offer,
}) => {
  const onCloseWithReason = (event, reason) => {
    if (reason === "backdropClick") {
      // Prevent closing on backdrop click
      return;
    }

    onClose()
    event.stopPropagation()
    setOpen(false)
  }
  const dispatch = useDispatch()
  const [diagnosticData, setDiagnosticData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')

  useEffect(() => {
    const fetchDiagnosticData = async () => {
      setIsLoading(true)
      try {
        const userToken = localStorage.getItem('userToken')
        const response = await axios.get(
          `${process.env.REACT_APP_NEST_JS_API}provider/request/file/${offer.id}/ESTIMATE`,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        setDiagnosticData(response.data[0])
      } catch (error) {
        console.error('Error fetching diagnostic file:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchDiagnosticData()
  }, [])

  const handleSubmit = () => {
    if (offer.services_fully_approved) {
      onSubmit()
      return
    }

    const payload = {
      supply_fee: parseFloat(shopSupplyFees),
      tax: parseFloat(tax),
    }
    try {
      dispatch<any>(updateOfferRevisedTax({ offerId: offer.id, body: payload }))
        .then(unwrapResult)
        .then((data: any) => {
          onSubmit()
        })
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  useEffect(() => {
    const newTotal = calculateSubTotalPrice()
    setFee(Number.isFinite(newTotal) ? newTotal.toFixed(2) : '0.00')
  }, [])

  useEffect(() => {
    const tax = parseFloat(offer?.tax || offer?.new_tax || '0.00').toFixed(2)
    const supply_fee = parseFloat(offer?.supply_fee || offer?.new_supply_fee || '0.00').toFixed(2)
    setTax(tax)
    setShopSupplyFees(supply_fee)
  }, [offer])

  const calculateSubTotalPrice = (): number => {
    if (!offer?.services || offer.services.length === 0) {
      return 0
    }

    return offer.services
      .filter((service) => service.is_approved)
      .reduce((total, service) => total + (service.price ? parseFloat(service.price) : 0), 0)
  }

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees?.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax?.replace(/,/g, '')) || 0
      const subtotal = calculateSubTotalPrice()

      return (subtotal + fees + taxes).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [shopSupplyFees, fee, tax])

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  const renderDiagnosticFile = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1, color: '#FF6600' }}>
          Diagnostic results
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Diagnostic Report
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
          Includes recommended services to resolve issues identified during diagnostics.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '16px',
            padding: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            backgroundColor: 'white',
            width: '50%',
          }}
        >
          <a href={diagnosticData?.url} target="_blank" rel="noopener noreferrer">
            <PdfIcon style={{ cursor: 'pointer' }} />
          </a>
          <div>
            <Typography variant="body2">{diagnosticData?.name}</Typography>
          </div>
        </Box>
      </Grid>
    )
  }

  const renderSummary = () => {
    if (offer.services.filter((service) => service.is_approved === true).length === 0) {
      return null
    }

    return (
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              mt: 5,
              pt: 3,
              width: '50%',
            }}
          >
            <Alert
              severity="warning"
              sx={{
                borderRadius: '12px',
                borderColor: '#FEC84B',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
            >
              <AlertTitle>
                <strong>Add revised shop supply fee and taxes</strong>
              </AlertTitle>
              Please add revised shop supply fee and taxes as only partial recommended services got
              accepted by customer
            </Alert>
          </Box>
          <Box
            sx={{
              width: '50%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2} mb={2}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '700',
                    color: 'black',
                    marginRight: '8px',
                    fontSize: '16px'
                  }}
                >
                  Subtotal:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '700',
                    color: '#FF6600',
                  }}
                >
                  {fee && formatPrice(fee)}
                </Typography>
              </Box>
              <Divider sx={{ marginY: '16px !important' }} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Stack spacing={1}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: 'black',
                    pr: 2,
                  }}
                >
                  Shop supply fees:
                </Typography>
              </Stack>
              <TextField
                value={shopSupplyFees}
                onChange={handleFeeChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { background: '#fff' },
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px', textAlign: 'right' },
                }}
                style={{ width: '30%' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Stack spacing={1}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: 'black',
                    pr: 2,
                  }}
                >
                  Tax:
                </Typography>
              </Stack>
              <TextField
                value={tax}
                onChange={handleTaxChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { background: '#fff' },
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px', textAlign: 'right' },
                }}
                style={{ width: '30%' }}
              />
            </Box>

            <Box display="flex" gap={4}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: 'black',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                Total:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: '#FF6600',
                }}
              >
                {totalFee && formatPrice(totalFee)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const renderInvoiceFeedback = () => {
    const instruction = offer.files.find((el) => el.type === 'INVOICE')?.instruction_admin_provider
    if (!instruction) return null

    return (
      <Box
        sx={{
          mt: 2,
          mb: 2,

          p: 2,
          border: 1,
          borderColor: '#FFB38C',
          borderRadius: 'borderRadius',
          bgcolor: 'background.paper',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: '#FF6600' }}>
          Carma Technician Feedback
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: '500', mb: 2, color: '#1D2939' }}>
          {instruction}
        </Typography>
      </Box>
    )
  }

  return (
    <Dialog open={open} onClose={onCloseWithReason} maxWidth="xl" fullWidth>
      <Box>
        <ModalTableHead
          onHide={onClose}
          service_request={{
            slug: offer.consumer_request.slug,
            vehicle: offer.vehicle,
            services: offer.services,
            drop_off_time: offer.drop_off_by,
            is_diagnostic: offer.consumer_request.type === 'DIAGNOSTIC',
            offer_amount: calculateOfferAmount(offer),
            pick_up_time: offer.pick_up_after,
            comment: offer.consumer_request.additional_information,
            status_text: statusText(offer),
          }}
        />
      </Box>
      <DialogContent>
        {renderInvoiceFeedback()}
        {offer?.consumer_request.files.length > 0 ? (
          <>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Photos and video by customer
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 2 }}>
              Uploaded by the customer to explain the issue
            </Typography>
            <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
              <FilePreview files={offer?.consumer_request.files || []} />
            </Box>
          </>
        ) : (
          null
        )}
        <Grid container spacing={2}>
          {diagnosticData ? renderDiagnosticFile() : ''}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FF6600', mb: 1 }}>
              Approved services
            </Typography>
            {offer.services
              .filter((service) => service.is_approved === true)
              .map((service, index) => (
                <Box key={service.id || index}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontFamily: APP_FONT,
                          fontWeight: '600',
                          color: 'black',
                        }}
                      >
                        {service.service.name}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: 'black',
                      }}
                    >
                      {formatPrice(service.price)}
                    </Typography>
                  </Box>
                  {index < offer.services.length - 1 && <Divider sx={{ marginY: '4px !important' }} />}
                </Box>
              ))}
            {!offer?.services_fully_approved ? renderSummary() : ''}
            {offer?.services_fully_approved ? (
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={4} mb={2}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: 'black',
                        marginRight: '8px',
                        fontSize: '20px'
                      }}
                    >
                      Subtotal:
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#FF6600',
                        fontSize: '20px'
                      }}
                    >
                      {fee && formatPrice(fee)}
                    </Typography>
                  </Box>

                </Box>

                <Divider sx={{ marginY: '12px !important' }} />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: 'black',
                        pr: 2,
                      }}
                    >
                      Shop supply fees:
                    </Typography>
                  </Stack>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#FF6600',
                    }}
                  >
                    {shopSupplyFees && formatPrice(shopSupplyFees)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: 'black',
                        pr: 2,
                      }}
                    >
                      Tax:
                    </Typography>
                  </Stack>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#FF6600',
                    }}
                  >
                    {tax && formatPrice(tax)}
                  </Typography>
                </Box>

                <Divider sx={{ marginY: '12px !important' }} />

                <Box display="flex" gap={2}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      fontSize: '20px',
                      color: 'black',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    Total:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#FF6600',
                    }}
                  >
                    {totalFee && formatPrice(totalFee)}
                  </Typography>
                </Box>
              </Box>
            ) : (
              ''
            )}
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={3}>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  handleSubmit()
                }}
                sx={{
                  width: 'fit-content',
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  paddingLeft: '32px',
                  paddingRight: '32px',
                  fontSize: '18px'
                }}
              >
                Mark service complete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Dialog>
  )
}

export default InvoiceUploadModal
