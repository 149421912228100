import React, { useState } from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  TableContainer,
  styled,
  Table,
  Button,
} from '@mui/material'
import { APP_FONT } from '../../../../../constants/app_font'
import Copy from '../../../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../../../assets/images/contentCopyFilled.svg'
import CloseIcon from '@mui/icons-material/Close'
import { Vehicle } from '../../../../../models/service_request'
import { ArrowTooltip } from './ui/arrow_tooltip'

interface TableHeadProps {
  onHide: () => void
  service_request: {
    slug: string
    vehicle: Vehicle
  }
}

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
  }
`

const ModalTableHeadShort: React.FC<TableHeadProps> = ({ onHide, service_request }) => {
  const [copiedVin, setCopiedVin] = useState<string | null>(null)
  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => setCopiedVin(null), 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  return (
    <TableContainer>
      <Table size="medium" sx={{ width: '100%' }}>
        <TableHead sx={{ backgroundColor: '#FF6600' }}>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              Request ID
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              Vehicle ID
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              Vehicle
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              VIN
            </TableCell>
            <TableCell align="right">
              <Button variant="contained" color="success" onClick={onHide} endIcon={<CloseIcon fontSize={'inherit'} />}
               sx={{
                borderRadius: 10,
                height: 40,
                width: 100,
                fontSize: 16,
                fontWeight: 600
               }}>CLOSE</Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT, width: 70 }}>
              {service_request.slug}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {service_request.vehicle.name}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {service_request.vehicle.year} {service_request.vehicle.make}{' '}
              {service_request.vehicle.model} {service_request.vehicle.trim ?? ''}
            </TableCell>
            <TableCell align="right" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                {service_request.vehicle?.vin || 'VIN not provided'}
                {service_request.vehicle?.vin && (
                  <ArrowTooltip title={copiedVin === service_request.vehicle?.vin ? 'Copied!' : ''}>
                    <StyledButton
                      onClick={(event: any) =>
                        handleCopyVin(event, service_request.vehicle?.vin ?? '')
                      }
                    >
                      <img
                        src={copiedVin === service_request.vehicle?.vin ? CopyFilled : Copy}
                        alt=""
                      />
                    </StyledButton>
                  </ArrowTooltip>
                )}
              </div>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ModalTableHeadShort
