import React, { useEffect, useMemo, useState } from 'react'
import {
  Box as MuiBox,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import DeleteIcon from '../../../../../assets/images/delete.svg'
import Autocomplete from '@mui/material/Autocomplete'
import { RecServicePhotoUpload } from './RecServicePhotoUpload'
import { APP_FONT } from '../../../../../constants/app_font'
import { Service } from '../inspection_upload/inspection_upload'
import { TirePositions } from '../inspection_upload/selects/TirePositions'
import { TireInfo } from '../inspection_upload/selects/TireInfo'
import { CrankingAmps } from '../inspection_upload/selects/CrankingAmps'
import { Axles } from '../inspection_upload/selects/Axles'
import { ReactComponent as Plus } from '../../../../../assets/images/plus.svg'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import { RecommendedService, RecommendedServicePart } from '../service.model'
import Decimal from 'decimal.js'
import { ProviderRequestServiceType } from '../../../../../models/offer_new'
import { MAX_LABOR_HOURS } from '../../../../../constants/constants'
import { Box, Flex, Group, Text } from '@mantine/core'
import { ServiceFormErrors } from '../service.validation'
import { MuiInputWrapper } from '../../../../../components/MuiInputWrapper'

interface RecServiceBlockProps {
  service: RecommendedService
  index: number
  recServices: RecommendedService[]
  services: Service[]
  requestedServices: ProviderRequestServiceType[]
  servicePrices: { [serviceId: string]: string }
  offer: {
    // incomplete
    id: string
    labor_rate: number | null
  }
  handleServiceChange: (
    index: number,
    field: keyof RecommendedService | string,
    value: string | string[] | number
  ) => void
  handleServicePartChange: (
    index: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => void
  handleRemoveService: (serviceId: string, serviceType: string | null) => void
  handleAddServicePart: (serviceIndex: number) => void
  handleImagesChange: (files: File[], index: number) => void
  refetchImages: (index: number) => void
  handleRemoveServicePart: (serviceIndex: number, partIndex: number) => void
  handleAdditionalDataChange: (
    index: number,
    field: string,
    value: string | string[] | number
  ) => void
  disableServiceChange?: boolean
  errors?: ServiceFormErrors
}

export const RecServiceBlock: React.FC<RecServiceBlockProps> = ({
  service,
  recServices,
  index,
  services,
  requestedServices,
  offer,
  handleServiceChange,
  handleServicePartChange,
  handleRemoveService,
  handleAddServicePart,
  handleImagesChange,
  refetchImages,
  handleRemoveServicePart,
  handleAdditionalDataChange,
  disableServiceChange,
  errors,
}) => {
  const [options, setOptions] = useState<Service[]>(services)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (offer.labor_rate === null) {
      console.warn('Labor rate is not set')
    }
  }, [offer.labor_rate])

  const fetchServices = async (query: string) => {
    if (query) {
      setLoading(true)
      setOptions([])
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NEST_JS_API}service?search=${query}&level=2`
        )
        const data = await response.json()

        const filteredOptions = data.items
          .filter(
            (item: any) =>
              item.name.toLowerCase().includes(query.toLowerCase()) && item.parent_id !== null
          )
          .filter((item) => !requestedServices.some((s) => s.service.id === item.id))

        setOptions(filteredOptions)
      } catch (error) {
        console.error('Failed to fetch services:', error)
      } finally {
        setLoading(false)
      }
    } else {
      setOptions(services)
    }
  }

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    fetchServices(newInputValue)
  }

  const selectedService = useMemo(() => {
    return services.find((s) => s.id === service.id)
  }, [services, service.id])

  const selectedServiceType = service.types.find((s) => s.id === service.type)

  const recommendedRequiredData = useMemo(() => {
    return (
      selectedServiceType?.recommend_required_data || selectedService?.recommend_required_data || []
    )
  }, [selectedServiceType, selectedService])

  const { IS_TIRE_POSITIONS, IS_TIRE_INFO, IS_CRANKING_AMPS, IS_FRONT_AXLE, IS_REAR_AXLE } = {
    IS_TIRE_POSITIONS: recommendedRequiredData.includes('TIRE_POSITIONS'),
    IS_TIRE_INFO: recommendedRequiredData.includes('TIRE_BRAND'),
    IS_CRANKING_AMPS: recommendedRequiredData.includes('COLD_CRANKING_AMPS'),
    IS_FRONT_AXLE:
      recommendedRequiredData.includes('LF_PAD') && recommendedRequiredData.includes('RF_PAD'),
    IS_REAR_AXLE:
      recommendedRequiredData.includes('LR_PAD') && recommendedRequiredData.includes('RR_PAD'),
  }

  const partsTotal = service.parts.reduce((acc, part) => {
    const partTotal = new Decimal(sanitizeNumber(part.price_per_unit)).mul(part.quantity)
    return acc.plus(partTotal)
  }, new Decimal(0))

  const laborTotal = new Decimal(sanitizeNumber(service.labor_hours)).mul(offer.labor_rate || 0)

  const total = partsTotal.plus(laborTotal)

  const totalPrice = total.toDecimalPlaces(2).toNumber()

  const typeSelectDisabled = !service.name || !service.types.length

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      mb={2}
      style={{
        width: '100%',
        backgroundColor: '#F9FAFB',
        padding: '12px',
        borderRadius: '8px',
        marginTop: '12px',
        marginBottom: '12px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
        border: '1px solid #E5E7EB',
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end" sx={{ mb: 2 }}>
        {service.serviceType !== 'DIAGNOSIS' && recServices.length > 0 && (
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              onClick={() => handleRemoveService(service.id, service.type)}
              size="small"
              sx={{
                padding: 0,
                gap: '8px',
              }}
            >
              <img src={DeleteIcon} alt="Delete" />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: APP_FONT,
                  fontWeight: 'bold',
                  color: '#727373',
                  textAlign: 'center',
                }}
              >
                Remove service
              </Typography>
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems="start"
        sx={{
          display: 'flex',
        }}
      >
        {service.serviceType === 'DIAGNOSIS' ? (
          <Grid item xs={6}>
            <TextField
              disabled={disableServiceChange}
              size="small"
              fullWidth
              value={selectedService?.name || undefined}
              InputProps={{
                readOnly: true,
                style: { background: '#fff' },
              }}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <MuiInputWrapper error={errors && errors.name?.message}>
                <Autocomplete
                  options={options.filter(
                    (option) => !requestedServices.some((s) => s.service.id === option.id)
                  )}
                  value={selectedService}
                  onChange={(event, newValue: any) => {
                    handleServiceChange(index, 'id', newValue?.id || '')
                  }}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option: any) => option.name}
                  loading={loading}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      error={errors?.name?.message !== undefined}
                      {...params}
                      label="Service"
                      placeholder="Select service"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      sx={{ backgroundColor: '#fff' }}
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )
                  }}
                  blurOnSelect={true}
                />
              </MuiInputWrapper>
            </Grid>
          </>
        )}

        {service.serviceType !== 'DIAGNOSIS' && (
          <Grid item xs={6}>
            <MuiInputWrapper error={errors?.type?.message}>
              <TextField
                error={errors?.type?.message !== undefined}
                select
                fullWidth
                label="Type"
                placeholder="Select service type"
                value={service.type ?? ''}
                onChange={(e) => handleServiceChange(index, 'type', e.target.value)}
                disabled={typeSelectDisabled}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: '50vh',
                      },
                    },
                  },
                }}
                sx={{ ...(!typeSelectDisabled ? { backgroundColor: '#fff' } : {}) }}
              >
                {service.types
                  .filter((type) => !requestedServices.some((s) => s.service.id === type.id))
                  .map((type) => (
                    <MenuItem key={`${type.id}_${type.name}`} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
              </TextField>
            </MuiInputWrapper>
          </Grid>
        )}
      </Grid>
      <MuiBox
        sx={{
          width: '100%',
        }}
      >
        {IS_TIRE_POSITIONS ? (
          <MuiInputWrapper error={errors?.additional_data?.TIRE_POSITIONS?.message}>
            <Box
              bd={
                errors?.additional_data?.TIRE_POSITIONS?.message
                  ? '1px solid #FF5C5C'
                  : '1px solid #E5E7EB'
              }
              style={{ borderRadius: '8px' }}
              p={10}
              mt={10}
            >
              <TirePositions
                tirePositions={service.additional_data?.TIRE_POSITIONS ?? []}
                values={{
                  LF_MEASUREMENT: service.additional_data?.LF_MEASUREMENT ?? '',
                  LR_MEASUREMENT: service.additional_data?.LR_MEASUREMENT ?? '',
                  RF_MEASUREMENT: service.additional_data?.RF_MEASUREMENT ?? '',
                  RR_MEASUREMENT: service.additional_data?.RR_MEASUREMENT ?? '',
                  LR_INSIDE_MEASUREMENT: service.additional_data?.LR_INSIDE_MEASUREMENT ?? '',
                  RR_INSIDE_MEASUREMENT: service.additional_data?.RR_INSIDE_MEASUREMENT ?? '',
                }}
                onChange={(field, value) => {
                  handleAdditionalDataChange(index, field, value)
                }}
                onTirePositionsChange={(value) => {
                  handleAdditionalDataChange(index, 'TIRE_POSITIONS', value)
                }}
                errors={{
                  LF_MEASUREMENT: errors?.additional_data?.LF_MEASUREMENT?.message,
                  LR_MEASUREMENT: errors?.additional_data?.LR_MEASUREMENT?.message,
                  RF_MEASUREMENT: errors?.additional_data?.RF_MEASUREMENT?.message,
                  RR_MEASUREMENT: errors?.additional_data?.RR_MEASUREMENT?.message,
                  LR_INSIDE_MEASUREMENT: errors?.additional_data?.LR_INSIDE_MEASUREMENT?.message,
                  RR_INSIDE_MEASUREMENT: errors?.additional_data?.RR_INSIDE_MEASUREMENT?.message,
                }}
              />
            </Box>
          </MuiInputWrapper>
        ) : null}
        {IS_TIRE_INFO ? (
          <TireInfo
            values={{
              TIRE_BRAND:
                typeof service.additional_data?.TIRE_BRAND === 'object'
                  ? // @ts-ignore
                    service.additional_data?.TIRE_BRAND?.name || ''
                  : service.additional_data?.TIRE_BRAND || '',
              TIRE_MODEL: service.additional_data?.TIRE_MODEL ?? '',
              WARRANTY: service.additional_data?.WARRANTY ?? 0,
            }}
            onChange={({ field, value }) => {
              handleAdditionalDataChange(index, field, value)
            }}
            errors={{
              TIRE_BRAND: errors?.additional_data?.TIRE_BRAND?.message,
              TIRE_MODEL: errors?.additional_data?.TIRE_MODEL?.message,
              WARRANTY: errors?.additional_data?.WARRANTY?.message,
            }}
          />
        ) : null}
        {IS_CRANKING_AMPS ? (
          <CrankingAmps
            values={{
              COLD_CRANKING_AMPS: service.additional_data?.COLD_CRANKING_AMPS ?? '',
              FACTORY_COLD_CRANKING_AMPS: service.additional_data?.FACTORY_COLD_CRANKING_AMPS ?? '',
            }}
            onChange={(field, value) => {
              handleAdditionalDataChange(index, field, value)
            }}
            errors={{
              COLD_CRANKING_AMPS: errors?.additional_data?.COLD_CRANKING_AMPS?.message,
              FACTORY_COLD_CRANKING_AMPS:
                errors?.additional_data?.FACTORY_COLD_CRANKING_AMPS?.message,
            }}
          />
        ) : null}
        <Axles
          values={{
            LF_PAD: service.additional_data?.LF_PAD ?? '',
            LR_PAD: service.additional_data?.LR_PAD ?? '',
            RF_PAD: service.additional_data?.RF_PAD ?? '',
            RR_PAD: service.additional_data?.RR_PAD ?? '',
          }}
          onChange={(field, value) => {
            handleAdditionalDataChange(index, field, value)
          }}
          isFront={IS_FRONT_AXLE}
          isRear={IS_REAR_AXLE}
          errors={{
            LF_PAD: errors?.additional_data?.LF_PAD?.message,
            LR_PAD: errors?.additional_data?.LR_PAD?.message,
            RF_PAD: errors?.additional_data?.RF_PAD?.message,
            RR_PAD: errors?.additional_data?.RR_PAD?.message,
          }}
        />
        <Flex
          style={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          <Text ff={APP_FONT} size="lg" c="black" fw={700}>
            Labor:
          </Text>
          <Flex style={{ flexGrow: 1 }} align={'center'} justify={'end'} gap={4}>
            <ServiceBlockText>Labor hours:</ServiceBlockText>
            <Text fw={800} c="#475467" mr="8px" size="md" ff={APP_FONT}>
              {service.labor_hours}
            </Text>
            <ServiceBlockText>hrs</ServiceBlockText>
            <ServiceBlockText>x</ServiceBlockText>
            <ServiceBlockText>Labor rate:</ServiceBlockText>
            <Text fw={800} c="#475467" size="md" ff={APP_FONT} ta={'right'}>
              {formatPrice(offer.labor_rate ?? 0)}
            </Text>
            <ServiceBlockText>/hr</ServiceBlockText>
            <ServiceBlockText>=</ServiceBlockText>
            <TextField
              label="Labor cost"
              type="text"
              value={formatPrice(
                new Decimal(sanitizeNumber(service.labor_price)).toDecimalPlaces(2)
              )}
              InputProps={{
                style: {
                  background: '#fff',
                },
                sx: {
                  height: '40px',
                },
              }}
              inputProps={{
                style: {
                  textAlign: 'right',
                },
              }}
              onChange={(e) => {
                const input = e.target.value.replace(/[^0-9/]/g, '')
                let laborCost = input === '' ? 0 : parseInt(input) / 100

                let laborHours = offer.labor_rate ? laborCost / offer.labor_rate : 0
                laborHours = Math.min(MAX_LABOR_HOURS, laborHours)

                laborCost = laborHours * (offer.labor_rate ?? 0)

                const formattedLaborCost = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
                  .format(laborCost)
                  .slice(1)

                // recalulate this after formatting, since we truncate
                laborHours = offer.labor_rate
                  ? sanitizeNumber(formattedLaborCost) / offer.labor_rate
                  : 0

                const formattedLaborHours = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 5,
                })
                  .format(laborHours)
                  .slice(1)

                handleServiceChange(index, 'labor_hours', formattedLaborHours)
                handleServiceChange(index, 'labor_price', formattedLaborCost)
              }}
            />
          </Flex>
        </Flex>

        <MuiBox
          sx={{
            marginTop: '16px',
          }}
        >
          <Text ff={APP_FONT} size="lg" c="black" fw={700} mt="20px" mb="8px">
            Parts:
          </Text>
          {service.parts?.length > 0 &&
            service.parts.map((part, partIndex) => (
              <MuiBox key={partIndex}>
                <Group w="100%" gap={16} justify="space-between" align="start">
                  <MuiInputWrapper error={errors?.parts[partIndex]?.name?.message} flex={1}>
                    <TextField
                      error={errors?.parts[partIndex]?.name?.message !== undefined}
                      size="small"
                      fullWidth
                      label="Part name"
                      placeholder="Enter part name"
                      value={part.name}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'name', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff' },
                      }}
                    />
                  </MuiInputWrapper>
                  <MuiInputWrapper error={errors?.parts[partIndex]?.quantity?.message}>
                    <TextField
                      error={errors?.parts[partIndex]?.quantity?.message !== undefined}
                      size="small"
                      label="Quantity"
                      value={part.quantity}
                      style={{ width: '100px' }}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'quantity', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff', textAlign: 'right' },
                      }}
                      inputProps={{
                        style: {
                          textAlign: 'right',
                        },
                      }}
                    />
                  </MuiInputWrapper>
                  <MuiInputWrapper error={errors?.parts[partIndex]?.price_per_unit?.message}>
                    <TextField
                      error={errors?.parts[partIndex]?.price_per_unit?.message !== undefined}
                      size="small"
                      label="Price per unit"
                      value={formatPrice(part.price_per_unit)}
                      style={{ width: '125px' }}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'price_per_unit', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff' },
                      }}
                      inputProps={{
                        style: {
                          textAlign: 'right',
                        },
                      }}
                    />
                  </MuiInputWrapper>
                  <TextField
                    disabled={true}
                    size="small"
                    label="Part cost"
                    style={{ width: '135px' }}
                    value={formatPrice(sanitizeNumber(part.price_per_unit) * part.quantity)}
                    aria-readonly
                    InputProps={{
                      sx: {
                        '& .MuiInputBase-input.Mui-disabled': {
                          WebkitTextFillColor: 'black',
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'right',
                      },
                    }}
                  />
                  <IconButton
                    onClick={() => handleRemoveServicePart(index, partIndex)}
                    size="large"
                  >
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Group>
                <MuiInputWrapper error={errors?.parts[partIndex]?.number?.message} mt={20} mb={20}>
                  <TextField
                    error={errors?.parts[partIndex]?.number?.message !== undefined}
                    size="small"
                    label="Part number"
                    placeholder="Enter part number"
                    value={part.number}
                    style={{ width: '250px' }}
                    onChange={(e) =>
                      handleServicePartChange(index, partIndex, 'number', e.target.value)
                    }
                    InputProps={{
                      style: { background: '#fff' },
                    }}
                  />
                </MuiInputWrapper>
              </MuiBox>
            ))}
        </MuiBox>
        <Button
          startIcon={<Plus />}
          variant="text"
          color="primary"
          onClick={() => handleAddServicePart(index)}
          sx={{
            fontSize: '14px',
            textTransform: 'none',
            color: '#FF6600',
            fontWeight: 'bold',
            padding: 0,
            paddingLeft: 1,
            margin: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <span style={{ marginLeft: '10px', margin: 2, padding: 2, alignItems: 'center' }}>
            Add part
          </span>
        </Button>
        {service.id !== '' && (service.types.length === 0 || service.type !== null) ? (
          <RecServicePhotoUpload
            offerId={offer.id}
            serviceId={service.type || service.id}
            onFilesChange={(files) => handleImagesChange(files, index)}
            onRefetchUploadedImages={() => {
              refetchImages(index)
            }}
            serviceName={service.name}
            files={service.imageFiles}
            existingFiles={service.uploadedImageFiles}
            error={errors?.imageFiles?.message}
          />
        ) : null}
      </MuiBox>
      <MuiBox
        display="flex"
        mt="12px"
        style={{
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Typography
          variant="body1"
          style={{
            fontWeight: '600',
            fontFamily: APP_FONT,
            fontSize: 16,
            marginRight: '16px',
            color: '#111827',
          }}
        >
          Service subtotal:
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontWeight: '600',
            fontFamily: APP_FONT,
            fontSize: 16,
            color: '#111827',
          }}
        >
          {formatPrice(totalPrice)}
        </Typography>
      </MuiBox>
    </Grid>
  )
}

const ServiceBlockText = ({
  children,
  style,
}: {
  children: React.ReactNode
  style?: React.CSSProperties
}) => (
  <Text fw={500} mr="8px" c="#475467" size="md" ff={APP_FONT}>
    {children}
  </Text>
)
