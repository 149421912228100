import React, { useState, useEffect } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  styled,
  TableCell,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { ServiceRequest } from '../../../models/service_request'
import { IconButton, Typography } from '@mui/material'
import socket from '../../../sockets/socket'
import { APP_FONT } from '../../../constants/app_font'
import { ReactComponent as RequestIcon } from '../../../assets/images/missed_request_icon.svg'
import { fetchMissedServiceRequests } from '../../../redux/actions/service_request'
import { formatDateTime } from '../../../utils/helper'
import { formatPrice } from '../../../components/helper/helper'
import ServiceList from '../components/service_list'

type Props = {
  formatCurrency: any
}

const StickyHeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  top: 0,
  zIndex: 10,
  position: 'sticky',
  boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)',
}))

export default function EnhancedTable(props: Props) {
  const { formatCurrency } = props
  const [currentRequests, setCurrentRequests] = React.useState<ServiceRequest[]>([])
  const dispatch = useDispatch()
  const providerId = localStorage.getItem('branchId')

  const getNewSRs = () => {
    if (providerId)
      dispatch<any>(fetchMissedServiceRequests({ providerId }))
        .then(unwrapResult)
        .then((current_requests: any) => {
          setCurrentRequests(current_requests)
        })
        .catch((rejectedValueOrSerializedError: any) => {
          console.log('error')
        })
  }

  const getOfferStatus = (sr: ServiceRequest) => {
    if (sr.status?.status.toLowerCase() === 'cancelled') {
      return 'Canceled request'
    } else if (sr.status?.status.toLowerCase() === 'expired' && sr.offers && sr.offers.length > 0) {
      return 'Request expired: no offer selected'
    } else if (
      sr.status?.status.toLowerCase() === 'expired' &&
      (!sr.offers || sr.offers.length === 0)
    ) {
      return 'Request expired: no offers'
    } else if (
      sr.offers.length > 0 &&
      sr.status?.status.toLowerCase() !== 'cancelled' &&
      sr.status?.status.toLowerCase() !== 'expired'
    ) {
      return 'Accepted another offer'
    }
  }

  const getMyBidAmount = (sr: ServiceRequest, myBranchId: string) => {
    const offer = sr.offers?.find((el) => el.provider_branch_id === myBranchId)
    if (!offer && sr.status?.status.toLowerCase() !== 'completed') {
      return 'N/A'
    } else if (sr.type === 'DIAGNOSTIC' && offer) {
      return offer?.provider_branch?.labor_rate
        ? `Labor rate: ${formatPrice(offer?.provider_branch?.labor_rate)}/hour`
        : 'N/A'
    } else if (
      offer &&
      sr.status?.status.toLowerCase() !== 'cancelled' &&
      sr.status?.status.toLowerCase() !== 'expired'
    ) {
      return formatCurrency.format(offer?.total_fee || 0)
    }
    return 'N/A'
  }

  const getBidAmount = (sr: ServiceRequest) => {
    const offer = sr.offers?.find((el) => el.is_accepted)
    if (!offer && sr.status?.status.toLowerCase() !== 'completed') {
      return 'N/A'
    } else if (sr.type === 'DIAGNOSTIC' && offer) {
      return offer?.provider_branch?.labor_rate
        ? `Labor rate: ${formatPrice(offer?.provider_branch?.labor_rate)}/hour`
        : 'N/A'
    } else if (
      offer &&
      sr.status?.status.toLowerCase() !== 'cancelled' &&
      sr.status?.status.toLowerCase() !== 'expired'
    ) {
      return formatCurrency.format(offer?.total_fee || 0)
    }
    return 'N/A'
  }

  useEffect(() => {
    getNewSRs()
    const providerId = localStorage.getItem('branchId')
    if (providerId) {
      socket.emit('subscribe', { branchId: providerId })
      socket.on('status-update', (payload) => {
        getNewSRs()
      })
    }

    return () => {
      socket.off('status-update')
    }
  }, [])


  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          marginTop: 5,
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      </Toolbar>
    )
  }

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          marginLeft: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: -13,
        }}
      >
        <RequestIcon />
        <Typography
          sx={{
            marginTop: 4,
            fontFamily: APP_FONT,
            fontSize: 16,
            letterSpacing: 1,
            textTransform: 'uppercase',
          }}
          id="tableTitle"
          component="div"
        >
          Missed Requests
        </Typography>
      </Box>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer sx={{ px: 2, maxHeight: 600, overflowY: 'auto' }}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Request ID
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Created on
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle ID
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT }}
                  align="left"
                >
                  Vehicle class
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Engine type / size
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Services
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Reason
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  My offer
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Winning offer
                </StickyHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRequests?.map((request: ServiceRequest) => {
                request.services =
                  request.offers
                    ?.find((offer): any => offer.is_accepted === true)
                    ?.services.filter((service: any) => service.is_approved === true) ||
                  request.services

                return (
                  <TableRow key={request.id}>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {request.slug ?? 'N/A'}
                    </TableCell>
                    <TableCell style={{ fontFamily: APP_FONT }} align="left">
                      {formatDateTime(request.created_at)}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">{`${
                      request.vehicle?.year
                    } ${request.vehicle?.make} ${request.vehicle?.model} ${
                      request.vehicle.trim ?? ''
                    }`}</TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }}>
                      <div style={{ maxWidth: '125px' }}>{request.vehicle?.name}</div>
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {request.vehicle?.class?.name ? request.vehicle?.class?.name : ''}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {[request.vehicle?.engines].join(', ')} {request.vehicle?.engine_size ? `/ ${request.vehicle?.engine_size}` : ''}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      <ServiceList offerOrRequest={request} />
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {getOfferStatus(request)}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {getMyBidAmount(request, providerId ?? '')}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {getBidAmount(request)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
