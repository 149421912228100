import imageCompression from 'browser-image-compression'
import moment from 'moment-timezone'

export const calculateTimeLeft = (time: any) => {
  const currentTime = new Date()
  const offerTime = new Date(time)
  offerTime.setMinutes(offerTime.getMinutes())
  const differenceInMs = offerTime.getTime() - currentTime.getTime()
  return Math.floor(differenceInMs / (1000 * 60))
}

export const formatDate = (dateString: string) => {
  return moment(dateString, 'MMMM DD').format(
    `MMM DD, ${dateString.includes('Morning') ? '[Morning]' : '[Evening]'}`
  )
}

export const convertToLocaleDate = (tempDate: string, timezone: string): string => {
  const dateInTargetTimezone = moment.tz(tempDate, timezone).format('YYYY-MM-DDTHH:mm:ss.SSS')
  const localZone = moment(dateInTargetTimezone).format('Z')
  const dateWithLocalZone = [dateInTargetTimezone, localZone].join('')

  return dateWithLocalZone
}

export const formatDateTime = (dateString: string): string => {
  return moment(dateString).format('MM/DD/YY h:mma')
}

export const collapseTimes = (times: string[]): string[] => {
  const sortedTimes = [...times]

  let collapsed: string[] = []
  let currentStart: string | null = null
  let currentEnd: string | null = null

  for (let i = 0; i < sortedTimes.length; i++) {
    let [start, end] = sortedTimes[i].split('-')

    if (currentStart === null) {
      currentStart = start
      currentEnd = end
    } else if (currentEnd === start) {
      currentEnd = end
    } else {
      collapsed.push(currentStart + '-' + currentEnd)
      currentStart = start
      currentEnd = end
    }
  }

  if (currentStart !== null) {
    collapsed.push(currentStart + '-' + currentEnd)
  }

  return collapsed
}

export const parseDate = (dateString: string): Date => {
  return moment(dateString, 'MMMM DD @ h:mma').toDate()
}

export const handleErrors = (error: any, rejectWithValue: (value: unknown) => void) => {
  if (error.response && error.response.data.message) {
    return rejectWithValue(error.response.data.message)
  } else {
    return rejectWithValue(error.message)
  }
}
interface FileWithUrl extends File {
  url: string
}

export async function compressImage(file: File): Promise<FileWithUrl | null> {
  if (file.size <= 1024 * 1024) {
    const url = URL.createObjectURL(file)
    const fileWithUrl: FileWithUrl = Object.assign(file, { url })
    return fileWithUrl
  }

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }

  try {
    const compressedBlob: Blob = await imageCompression(file, options)
    const compressedFile = new File([compressedBlob], file.name, {
      type: file.type,
      lastModified: Date.now(),
    })

    const url = URL.createObjectURL(compressedBlob)
    const compressedFileWithUrl: FileWithUrl = Object.assign(compressedFile, { url })

    return compressedFileWithUrl
  } catch (error) {
    console.error(error)
    return null
  }
}

export function formatPhoneNumber(value: any) {
  const phoneNumber = (value ? value.toString() : '').replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export function formatFetchedPhoneNumber(value: string) {
  let phoneNumber = (value ? value.toString() : '').replace(/[^\d]/g, '')

  if (phoneNumber.length === 11 && phoneNumber.startsWith('1')) {
    phoneNumber = phoneNumber.substring(1)
  }

  const phoneNumberLength = phoneNumber.length
  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export const convertToTimezone = (date: Date, timezone: string | null): Date => {
  if (!date || !timezone || !moment.tz.zone(timezone)) {
    console.error('Invalid date or timezone in convertToTimezone')
    return date
  }

  try {
    const momentDate = moment(date).tz(timezone)
    return momentDate.toDate()
  } catch (error) {
    console.error('Error in convertToTimezone:', error)
    return date
  }
}

export function convertTZ(dateString: string, tzString: string) {
  const date = new Date(dateString)
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: tzString }))
  const offset = tzDate.getTime() - utcDate.getTime()

  return new Date(date.getTime() + offset).toISOString()
}
