import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  Button as MuiButton,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SuccessImg from '../../../../../assets/images/success.png'
import QRCode from 'react-qr-code'
import { useDeleteRecServiceImageMutation } from '../../../../../redux/api/serviceApi/serviceApi'
import { OfferServiceImage } from '../../../../../redux/api/serviceApi/serviceApi.types'
import { APP_FONT } from '../../../../../constants/app_font'
import { IconCheck, IconCopy, IconRefresh } from '@tabler/icons-react'
import { Anchor, Button, CopyButton, Group, LoadingOverlay } from '@mantine/core'
import { IconCloudUpload } from '@tabler/icons-react'
import { MuiInputWrapper } from '../../../../../components/MuiInputWrapper'

type RecServicePhotoUploadProps = {
  serviceName: string
  onFilesChange: (files: File[]) => void
  onRefetchUploadedImages: () => void
  serviceId: string
  offerId: string
  files: File[]
  existingFiles: OfferServiceImage[]
  error?: string
}

export const RecServicePhotoUpload = ({
  serviceName,
  onFilesChange,
  onRefetchUploadedImages,
  files,
  serviceId,
  offerId,
  existingFiles,
  error,
}: RecServicePhotoUploadProps) => {
  const [open, setOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const qrCodeUploadUrl = `${process.env.REACT_APP_CUSTOMER_APP_URL}/provider-qrUpload?serviceId=${serviceId}&offerId=${offerId}`

  const [deleteImage] = useDeleteRecServiceImageMutation()

  const onDelete = async (imageId: string) => {
    try {
      await deleteImage({
        service_id: serviceId,
        offer_id: offerId,
        image_id: imageId,
      }).unwrap()
    } catch (e) {
      console.error(e)
    }
  }

  const appendFiles = useCallback(
    (newFiles: File[]) => {
      onFilesChange([...files, ...newFiles])
    },
    [onFilesChange, files]
  )

  const handleUploadClicked = useCallback(() => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = true
    input.accept = 'image/png, image/jpeg, image/heic, image/jpg'
    input.addEventListener('change', (e) => {
      const fileList = (e.target as HTMLInputElement).files
      if (!fileList) {
        return
      }
      const newFiles = Array.from(fileList)
      appendFiles(newFiles)
    })
    document.body.appendChild(input)
    input.click()
  }, [appendFiles])

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      // accept only PNG, JPEG, JPG, HEIC file types

      if (e.dataTransfer.items) {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (
            e.dataTransfer.items[i].kind === 'file' &&
            e.dataTransfer.items[i].type.match('^image/')
          ) {
            continue
          }
          return
        }
      }

      const files = Array.from(e.dataTransfer.files)
      appendFiles(files)
    },
    [appendFiles]
  )

  const onRemove = useCallback(
    (index: number) => {
      onFilesChange(files.filter((_, i) => i !== index))
    },
    [files, onFilesChange]
  )

  const onSubmit = () => {
    setIsSubmitted(true)
    setOpen(false)
  }

  const hasUploadedImages = existingFiles.length > 0 || files.length > 0

  return (
    <>
      <MuiInputWrapper error={error} mt={20} mb={20}>
        <Group
          onClick={() => {
            onRefetchUploadedImages()
            setOpen(true)
          }}
          align="center"
          justify="space-between"
          bd={error ? '1px solid red' : '1px solid #ffc9b3'}
          p="12px 8px"
          style={{
            cursor: 'pointer',
            borderRadius: '8px',
          }}
        >
          {hasUploadedImages ? (
            <img
              style={{
                height: 24,
                width: 24,
              }}
              src={SuccessImg}
              alt="uploaded"
            />
          ) : null}
          <div>
            <div
              style={{
                fontWeight: 600,
                fontSize: 16,
                fontFamily: APP_FONT,
              }}
            >
              Photo Upload Required
            </div>
            <div
              style={{
                fontSize: 12,
                fontFamily: APP_FONT,
              }}
            >
              Please upload pictures of the issue.
            </div>
          </div>
          <div
            onClick={onRefetchUploadedImages}
            style={{
              fontFamily: APP_FONT,
              textDecoration: 'underline',
              color: '#ff6600',
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            {isSubmitted ? 'See uploaded files' : 'Upload images'}
          </div>
        </Group>
      </MuiInputWrapper>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: {
            padding: 20,
            maxHeight: '90vh',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div>
            <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: APP_FONT }}>
              Photo upload
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, fontFamily: APP_FONT }}>
              Please upload pictures of the issue.
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={() => {
              setOpen(false)
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        <DialogContent style={{ overflow: 'auto', maxHeight: '80vh' }}>
          <Box display="flex" flexDirection="row">
            <Typography variant="body1" sx={{ fontFamily: APP_FONT, mr: '5px' }}>
              Service requiring photo upload:
            </Typography>
            <Typography
              variant="body1"
              sx={{ mb: 1, color: '#ff6600', fontWeight: 'bold', fontFamily: APP_FONT }}
            >
              {serviceName}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box
              onDragOver={(event) => event.preventDefault()}
              onDrop={onDrop}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={'2px'}
              style={{
                border: '2px dashed #C4C4C4',
                borderRadius: '4px',
                padding: '20px',
                marginBottom: '16px',
                transition: 'background-color 0.3s',
                backgroundColor: '#FAFAFA',
                width: '500px',
              }}
            >
              <IconCloudUpload style={{ fontSize: '50px', color: '#C4C4C4' }} />
              <Typography>
                <MuiButton
                  variant="text"
                  component="span"
                  style={{ textTransform: 'none', padding: 0, fontSize: '16px' }}
                  color="warning"
                  onClick={handleUploadClicked}
                >
                  Click to upload
                </MuiButton>{' '}
                or drag and drop
              </Typography>
              <Typography variant="caption">PNG, JPG, JPEG or HEIC (max. 25 MB)</Typography>
            </Box>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: 20,
              }}
            >
              {serviceId && offerId ? (
                <>
                  <Typography sx={{ mb: 1, fontFamily: APP_FONT }}>
                    <b>OR</b> upload from another device
                  </Typography>
                  <QRCode value={qrCodeUploadUrl} size={150} />
                  <span>
                    <Anchor
                      href={qrCodeUploadUrl}
                      target="_blank"
                      style={{
                        marginTop: 3,
                        fontFamily: APP_FONT,
                        color: '#ff6600',
                        fontWeight: 600,
                      }}
                      fz="md"
                    >
                      Upload Link
                    </Anchor>
                    <CopyButton value={qrCodeUploadUrl}>
                      {({ copied, copy }) => (
                        <Button onClick={copy} variant="subtle" color="#ff6600" size="compact-sm">
                          {copied ? <IconCheck size="16px" /> : <IconCopy size="16px" />}
                        </Button>
                      )}
                    </CopyButton>
                  </span>
                </>
              ) : null}
            </div>
          </Box>
          <div>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginTop: '24px',
                marginBottom: '10px',
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontFamily: APP_FONT }}>
                {hasUploadedImages ? 'Uploaded files' : 'No files uploaded'}
              </Typography>
              <Button
                leftSection={<IconRefresh size={18} />}
                variant="filled"
                color="orange"
                onClick={onRefetchUploadedImages}
                style={{ height: '25px', fontFamily: APP_FONT }}
              >
                Refresh the photo list
              </Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {existingFiles.map((file) => (
                <FileItem key={file.id} img={file} onRemove={() => onDelete(file.id)} />
              ))}
              {files.map((file, index) => (
                <FileItem key={file.name} file={file} onRemove={() => onRemove(index)} />
              ))}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
            }}
          >
            <MuiButton
              variant="contained"
              color="warning"
              onClick={onSubmit}
              sx={{
                fontFamily: APP_FONT,
                margin: '0 auto',
                width: 'fit-content',
                borderRadius: '20px',
                textTransform: 'none',
                fontWeight: 'bold',
                paddingLeft: '32px',
                paddingRight: '32px',
                fontSize: '18px'
              }}
            >
              Save all photos
            </MuiButton>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

const FileItem = memo(
  ({ file, onRemove, img }: { file?: File; img?: OfferServiceImage; onRemove: () => void }) => {
    const [convertedUrl, setConvertedUrl] = useState<string>()
    const [loading, setLoading] = useState(true)

    // Wrap in useEffect to use async/await since HEIC conversion is slow
    useEffect(() => {
      const convertAndSetUrl = async () => {
        if (file?.type === 'image/heic') {
          try {
            // Dynamically import this fat package
            const heic2any = (await import('heic2any')).default
            const converted = await heic2any({ blob: file, toType: 'image/jpeg' })
            const url = URL.createObjectURL(Array.isArray(converted) ? converted[0] : converted)
            setConvertedUrl(url)
          } catch (error) {
            console.error('Error converting image:', error)
          } finally {
            setLoading(false)
          }
        } else if (file) {
          setConvertedUrl(URL.createObjectURL(file))
          setLoading(false)
        } else {
          setConvertedUrl(img?.url)
          setLoading(false)
        }
      }

      convertAndSetUrl()

      return () => {
        if (convertedUrl) {
          URL.revokeObjectURL(convertedUrl)
        }
      }
    }, [file, img])
    return (
      <div
        style={{
          display: 'flex',
          border: '1px solid #eaecf0',
          borderRadius: 12,
          padding: 12,
          position: 'relative',
        }}
      >
        <LoadingOverlay visible={loading} />
        <img
          src={convertedUrl}
          alt={file?.name || img?.name}
          style={{
            width: 100,
            height: 70,
            objectFit: 'cover',
            borderRadius: 12,
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            flex: 1,
            marginLeft: 12,
          }}
        >
          <Typography sx={{ fontFamily: APP_FONT }}>{file?.name || img?.name}</Typography>
        </Box>
        <IconButton aria-label="close" color="inherit" size="medium" onClick={onRemove}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
    )
  }
)
