import React from 'react'
import { TextField } from '@mui/material'

import { MuiInputWrapper } from '../../../../../../components/MuiInputWrapper'

type TireWarrantyMilesInputProps = {
  value: number | string
  onChange: (value: number | string) => void
  error?: string
}

export const TireWarrantyMilesInput = ({
  value = '',
  onChange,
  error,
}: TireWarrantyMilesInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '')
    if (inputValue === '') {
      onChange('')
    } else {
      const numberValue = parseInt(inputValue, 10)
      onChange(numberValue)
    }
  }

  return (
    <MuiInputWrapper error={error}>
      <TextField
        error={error !== undefined}
        fullWidth
        label="Warranty (in miles)"
        placeholder="Enter miles"
        type="text"
        value={value !== undefined ? value.toLocaleString('en-US') : ''}
        onChange={handleChange}
        InputProps={{
          style: { background: '#fff', width: '100%' },
        }}
      />
    </MuiInputWrapper>
  )
}
