import React, { useMemo } from 'react'
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import DeleteIcon from '../../../../../assets/images/delete.svg'
import { Decimal } from 'decimal.js'
import { APP_FONT } from '../../../../../constants/app_font'
import { TirePositions } from '../inspection_upload/selects/TirePositions'
import { CrankingAmps } from '../inspection_upload/selects/CrankingAmps'
import { Axles } from '../inspection_upload/selects/Axles'
import { useGetParentService } from '../../../../../hooks/useGetParentService'
import { TireInfo } from '../inspection_upload/selects/TireInfo'
import { Vehicle } from '../../../../../models/service_request'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import { ReactComponent as Plus } from '../../../../../assets/images/plus.svg'
import { RecommendedServicePart } from '../service.model'
import { MAX_LABOR_HOURS } from '../../../../../constants/constants'
import { Flex, Text } from '@mantine/core'
import { ProviderRequestServiceAdditionalDataType } from '../../../../../models/offer_new'
import { ServiceFormErrors } from '../service.validation'
import { MuiInputWrapper } from '../../../../../components/MuiInputWrapper'

const AdditionalDataInfo = ({ label, value }) => {
  return (
    <Box display="flex" mt="5px">
      <Typography
        variant="body1"
        style={{
          fontWeight: 'regular',
          fontFamily: APP_FONT,
          fontSize: 14,
          marginRight: '5px',
        }}
      >
        {label}:
      </Typography>
      <Typography
        variant="body1"
        style={{ fontWeight: 'bold', fontFamily: APP_FONT, fontSize: 14 }}
      >
        {value}
      </Typography>
    </Box>
  )
}

export type OfferBlockService = {
  id: string
  name: string
  type: string | null
  types: any[] | null
  price: number
  serviceType: string
  labor_hours: number
  labor_price: number
  labor_rate: number
  parts: RecommendedServicePart[]
  request_additional_data: ProviderRequestServiceAdditionalDataType | null
  additional_data: ProviderRequestServiceAdditionalDataType | null
  vehicle: Vehicle
}

interface OfferBlockProps {
  service: OfferBlockService
  index: number
  handleServiceChange: (
    index: number,
    field: keyof OfferBlockService | string,
    value: string | string[] | number
  ) => void
  handleServicePartChange: (
    index: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => void
  handleAddServicePart: (serviceIndex: number) => void
  handleRemoveServicePart: (serviceIndex: number, partIndex: number) => void
  handleAdditionalDataChange: (
    index: number,
    field: string,
    value: string | string[] | number
  ) => void
  disableServiceChange?: boolean
  errors?: ServiceFormErrors
}

export const OfferServiceBlock: React.FC<OfferBlockProps> = ({
  service,
  index,
  handleServiceChange,
  handleServicePartChange,
  handleAddServicePart,
  handleRemoveServicePart,
  handleAdditionalDataChange,
  disableServiceChange,
  errors,
}) => {
  const { data: parentService } = useGetParentService(service?.type || service?.id)
  // TODO: is this just the same as getting the service itself?
  const serviceChild = useMemo(() => parentService?.child, [parentService])

  const offerRequiredData = useMemo(() => {
    return serviceChild?.offer_required_data || parentService?.offer_required_data || []
  }, [serviceChild, parentService])

  const { IS_TIRE_POSITIONS, IS_TIRE_BRAND, IS_CRANKING_AMPS, IS_FRONT_AXLE, IS_REAR_AXLE } =
    useMemo(() => {
      return {
        IS_TIRE_POSITIONS: offerRequiredData.includes('TIRE_POSITIONS'),
        IS_TIRE_BRAND: offerRequiredData.includes('TIRE_BRAND'),
        IS_CRANKING_AMPS: offerRequiredData.includes('COLD_CRANKING_AMPS'),
        IS_FRONT_AXLE: offerRequiredData.includes('LF_PAD') && offerRequiredData.includes('RF_PAD'),
        IS_REAR_AXLE: offerRequiredData.includes('LR_PAD') && offerRequiredData.includes('RR_PAD'),
      }
    }, [offerRequiredData])

  const tireInfo = useMemo(() => {
    return service.vehicle?.tires
      ?.map((tire) => `${tire.width}/${tire.ratio}${tire.construction}${tire.diameter}`)
      .join(', ')
  }, [service.vehicle])

  const totalPrice =
    service.parts.reduce(
      (acc, part) => acc + sanitizeNumber(part.price_per_unit) * part.quantity,
      0
    ) +
    sanitizeNumber(service.labor_hours) * service.labor_rate

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        alignItems="start"
        mb={2}
        style={{
          backgroundColor: '#F9FAFB',
          padding: '12px',
          borderRadius: '8px',
          marginTop: '12px',
          marginBottom: '12px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          border: '1px solid #E5E7EB',
        }}
      >
        <Grid item xs={6}>
          <TextField
            disabled={true}
            fullWidth
            label="Service"
            value={
              service.name === 'Full general diagnostic'
                ? 'Full general diagnostic'
                : serviceChild?.name
            }
            InputProps={{
              readOnly: true,
              sx: {
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Type"
            value={parentService?.name}
            InputProps={{
              readOnly: true,
              sx: {
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
              },
            }}
            disabled={true}
          />
        </Grid>

        <Box
          sx={{
            padding: '0 16px',
            width: '100%',
          }}
        >
          {IS_TIRE_BRAND ? (
            <>
              <AdditionalDataInfo label="Tire size" value={tireInfo} />
              {Array.isArray(service.additional_data?.TIRE_POSITIONS) ? (
                <AdditionalDataInfo
                  label="# of tires to be replaced"
                  value={service.additional_data?.TIRE_POSITIONS.length}
                />
              ) : null}
              <AdditionalDataInfo
                label="Tires to be replaced"
                value={
                  Array.isArray(service.additional_data?.TIRE_POSITIONS)
                    ? service.additional_data?.TIRE_POSITIONS.join(', ')
                    : service.additional_data?.TIRE_POSITIONS || ''
                }
              />
              <Typography
                variant="body1"
                style={{
                  fontWeight: 'regular',
                  fontFamily: APP_FONT,
                  fontSize: 14,
                  marginRight: '5px',
                  marginTop: '5px',
                }}
              >
                {service.request_additional_data?.WARRANTY ? (
                  <>
                    Quote your lowest priced economy tire with tread warranty greater than or equal
                    to <strong>{service.request_additional_data.WARRANTY.toLocaleString()}</strong>{' '}
                    miles
                  </>
                ) : (
                  <>Quote your lowest priced economy tire</>
                )}
              </Typography>
            </>
          ) : null}
          {IS_TIRE_POSITIONS ? (
            <TirePositions
              tirePositions={service.additional_data?.TIRE_POSITIONS || []}
              values={{
                LF_MEASUREMENT: service.additional_data?.LF_MEASUREMENT || '',
                LR_MEASUREMENT: service.additional_data?.LR_MEASUREMENT || '',
                RF_MEASUREMENT: service.additional_data?.RF_MEASUREMENT || '',
                RR_MEASUREMENT: service.additional_data?.RR_MEASUREMENT || '',
                LR_INSIDE_MEASUREMENT: service.additional_data?.LR_INSIDE_MEASUREMENT || '',
                RR_INSIDE_MEASUREMENT: service.additional_data?.RR_INSIDE_MEASUREMENT || '',
              }}
              onChange={(field, value) => {
                handleAdditionalDataChange(index, field, value)
              }}
              onTirePositionsChange={(value) => {
                handleAdditionalDataChange(index, 'TIRE_POSITIONS', value)
              }}
            />
          ) : null}
          {IS_TIRE_BRAND ? (
            <TireInfo
              values={{
                TIRE_BRAND:
                  typeof service.additional_data?.TIRE_BRAND === 'object'
                    ? // @ts-ignore
                      service.additional_data?.TIRE_BRAND?.name || ''
                    : service.additional_data?.TIRE_BRAND || '',
                TIRE_MODEL: service.additional_data?.TIRE_MODEL ?? '',
                WARRANTY: service.additional_data?.WARRANTY || 0,
              }}
              onChange={({ field, value }) => {
                handleAdditionalDataChange(index, field, value)
              }}
              errors={{
                TIRE_BRAND: errors?.additional_data?.TIRE_BRAND?.message,
                TIRE_MODEL: errors?.additional_data?.TIRE_MODEL?.message,
                WARRANTY: errors?.additional_data?.WARRANTY?.message,
              }}
            />
          ) : null}
          {IS_CRANKING_AMPS ? (
            <CrankingAmps
              values={{
                // @ts-ignore
                COLD_CRANKING_AMPS: service.additional_data?.COLD_CRANKING_AMPS,
                // @ts-ignore
                FACTORY_COLD_CRANKING_AMPS: service.additional_data?.FACTORY_COLD_CRANKING_AMPS,
              }}
              onChange={(field, value) => {
                handleAdditionalDataChange(index, field, value)
              }}
            />
          ) : null}
          <Axles
            values={{
              // @ts-ignore
              LF_PAD: service.additional_data?.LF_PAD,
              // @ts-ignore
              LR_PAD: service.additional_data?.LR_PAD,
              // @ts-ignore
              RF_PAD: service.additional_data?.RF_PAD,
              // @ts-ignore
              RR_PAD: service.additional_data?.RR_PAD,
            }}
            onChange={(field, value) => {
              handleAdditionalDataChange(index, field, value)
            }}
            isFront={IS_FRONT_AXLE}
            isRear={IS_REAR_AXLE}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '40px',
              marginBottom: '20px',
            }}
          >
            <Text ff={APP_FONT} size="lg" c="black" fw={700}>
              Labor:
            </Text>
            <Flex style={{ flexGrow: 1 }} align={'center'} justify={'end'} gap={4}>
              <ServiceBlockText>Labor hours:</ServiceBlockText>
              <Text fw={800} c="#475467" mr="8px" size="md" ff={APP_FONT}>
                {service.labor_hours}
              </Text>
              <ServiceBlockText>hrs</ServiceBlockText>
              <ServiceBlockText>x</ServiceBlockText>
              <ServiceBlockText>Labor rate:</ServiceBlockText>
              <Text fw={800} c="#475467" size="md" ff={APP_FONT} ta={'right'}>
                {formatPrice(service.labor_rate)}
              </Text>
              <ServiceBlockText>/hr</ServiceBlockText>
              <ServiceBlockText>=</ServiceBlockText>
              <TextField
                disabled={disableServiceChange}
                label="Labor cost"
                type="text"
                value={formatPrice(
                  new Decimal(sanitizeNumber(service.labor_price)).toDecimalPlaces(2)
                )}
                style={{ flexGrow: 1, maxWidth: '300px' }}
                InputProps={{
                  style: {
                    background: '#fff',
                  },
                  sx: {
                    height: '40px',
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: 'black',
                      background: '#F9FAFB',
                    },
                  },
                }}
                inputProps={{
                  style: {
                    textAlign: 'right',
                  },
                }}
                onChange={(e) => {
                  const input = e.target.value.replace(/[^0-9/]/g, '')
                  let laborCost = input === '' ? 0 : parseInt(input) / 100

                  let laborHours = laborCost / service.labor_rate
                  laborHours = Math.min(MAX_LABOR_HOURS, laborHours)

                  laborCost = laborHours * (service.labor_rate ?? 0)

                  const formattedLaborCost = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                    .format(laborCost)
                    .slice(1)

                  // recalulate this after formatting, since we truncate
                  laborHours = service.labor_rate
                    ? sanitizeNumber(formattedLaborCost) / service.labor_rate
                    : 0

                  const formattedLaborHours = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 5,
                  })
                    .format(laborHours)
                    .slice(1)

                  handleServiceChange(index, 'labor_hours', formattedLaborHours)
                  handleServiceChange(index, 'labor_price', formattedLaborCost)
                }}
              />
            </Flex>
          </Box>

          <Box sx={{ marginTop: '12px' }}>
            <Text ff={APP_FONT} size="lg" c="black" fw={700} mt="20px" mb="8px">
              Parts:
            </Text>

            {service.parts.length > 0 &&
              service.parts.map((part, partIndex) => (
                <Box key={partIndex}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      gap: '16px',
                    }}
                  >
                    <MuiInputWrapper error={errors?.parts[partIndex]?.name?.message} flex={1}>
                      <TextField
                        size="small"
                        label="Part name"
                        placeholder="Enter part name"
                        value={part.name}
                        onChange={(e) =>
                          handleServicePartChange(index, partIndex, 'name', e.target.value)
                        }
                        InputProps={{
                          style: { background: '#fff' },
                        }}
                        style={{ width: '100%' }}
                        error={errors?.parts[partIndex]?.name === undefined ? false : true}
                      />
                    </MuiInputWrapper>
                    <MuiInputWrapper error={errors?.parts[partIndex]?.quantity?.message}>
                      <TextField
                        error={errors?.parts[partIndex]?.quantity === undefined ? false : true}
                        size="small"
                        label="Quantity"
                        type="number"
                        value={part.quantity.toString()}
                        onChange={(e) =>
                          handleServicePartChange(index, partIndex, 'quantity', e.target.value)
                        }
                        InputProps={{
                          style: { background: '#fff' },
                        }}
                        inputProps={{ style: { textAlign: 'right' } }}
                        style={{ maxWidth: '125px' }}
                      />
                    </MuiInputWrapper>
                    <MuiInputWrapper error={errors?.parts[partIndex]?.price_per_unit?.message}>
                      <TextField
                        error={
                          errors?.parts[partIndex]?.price_per_unit === undefined ? false : true
                        }
                        size="small"
                        label="Price per unit"
                        value={formatPrice(part.price_per_unit)}
                        onChange={(e) =>
                          handleServicePartChange(
                            index,
                            partIndex,
                            'price_per_unit',
                            e.target.value
                          )
                        }
                        InputProps={{
                          style: { background: '#fff' },
                        }}
                        inputProps={{ style: { textAlign: 'right' } }}
                      />
                    </MuiInputWrapper>
                    <TextField
                      disabled={true}
                      size="small"
                      label="Part cost"
                      value={formatPrice(sanitizeNumber(part.price_per_unit) * part.quantity)}
                      aria-readonly
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                          },
                        },
                      }}
                      inputProps={{ style: { textAlign: 'right' } }}
                    />
                    <IconButton
                      onClick={() => handleRemoveServicePart(index, partIndex)}
                      size="large"
                    >
                      <img src={DeleteIcon} alt="Delete" />
                    </IconButton>
                  </Box>
                  <MuiInputWrapper
                    error={errors?.parts[partIndex]?.number?.message}
                    mt={20}
                    mb={20}
                  >
                    <TextField
                      error={errors?.parts[partIndex]?.number === undefined ? false : true}
                      size="small"
                      label="Part number"
                      placeholder="Enter part number"
                      value={part.number}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'number', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff' },
                      }}
                      style={{ width: '30%' }}
                    />
                  </MuiInputWrapper>
                </Box>
              ))}
          </Box>
          {!disableServiceChange && (
            <Button
              startIcon={<Plus />}
              variant="text"
              color="primary"
              onClick={() => handleAddServicePart(index)}
              sx={{
                fontSize: '14px',
                textTransform: 'none',
                color: '#FF6600',
                fontWeight: 'bold',
                padding: 0,
                paddingLeft: 1,
                margin: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <span style={{ marginLeft: '10px', margin: 2, padding: 2, alignItems: 'center' }}>
                Add part
              </span>
            </Button>
          )}
          <Box
            display="flex"
            mt="12px"
            style={{
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontWeight: '600',
                fontFamily: APP_FONT,
                fontSize: 16,
                marginRight: '16px',
                color: '#111827',
              }}
            >
              Service total:
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontWeight: '600',
                fontFamily: APP_FONT,
                fontSize: 16,
                color: '#111827',
              }}
            >
              {formatPrice(totalPrice)}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </React.Fragment>
  )
}

const ServiceBlockText = ({
  children,
  style,
}: {
  children: React.ReactNode
  style?: React.CSSProperties
}) => (
  <Text fw={500} mr="8px" c="#475467" size="md" ff={APP_FONT}>
    {children}
  </Text>
)
