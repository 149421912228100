import { Badge, HoverCard, rem, Stack, Text } from '@mantine/core'
import { IconExclamationCircle } from '@tabler/icons-react'

export type FlattenedError = { field: string; message: string }

const iconSizeMap = {
  md: 14,
  lg: 18,
}

export const ErrorSummary = ({
  errors,
  size = 'md',
}: {
  errors: FlattenedError[]
  size?: 'md' | 'lg'
}) => {
  if (errors.length === 0) {
    return null
  }
  return (
    <HoverCard zIndex={1400}>
      <HoverCard.Target>
        <Badge
          size={size}
          color="red"
          leftSection={
            <IconExclamationCircle
              style={{ width: rem(iconSizeMap[size]), height: rem(iconSizeMap[size]) }}
            />
          }
        >
          {errors.length} error
          {errors.length > 1 ? 's' : ''}
        </Badge>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack gap={4}>
          {errors.map(({ field, message }) => (
            <ErrorItem key={field} fieldName={field} errorMessage={message} />
          ))}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
const ErrorItem = ({ fieldName, errorMessage }: { fieldName: string; errorMessage: string }) => {
  return (
    <Stack key={fieldName} justify="center">
      <Text size={'sm'}>
        <strong>{fieldName ? `${fieldName}: ` : null}</strong>
        {errorMessage}
      </Text>
    </Stack>
  )
}
