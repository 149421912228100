import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { listValidations } from '../../../../../../redux/api/serviceApi/listValidations'

import { MuiInputWrapper } from '../../../../../../components/MuiInputWrapper'

type RfPadSelectProps = {
  value: string
  onChange: (value: string) => void
  error?: string
}

export const RfPadSelect = ({ value, onChange, error }: RfPadSelectProps) => {
  const items = listValidations.RF_PAD.permitted_values

  return (
    <Autocomplete
      size="small"
      value={value || ''}
      onChange={(event, newValue: any) => onChange(newValue)}
      options={items}
      getOptionLabel={(option) => option}
      getOptionKey={(option) => option}
      renderInput={(params) => (
        <MuiInputWrapper error={error}>
          <TextField
            {...params}
            label="RF pad"
            placeholder="Select or search"
            error={error !== undefined}
            style={{
              background: '#fff',
            }}
          />
        </MuiInputWrapper>
      )}
      isOptionEqualToValue={(option, value) => option === value}
      sx={{ width: '100%' }}
      disableClearable
      freeSolo
    />
  )
}
