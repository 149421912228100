import { Box, Button, Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { ProviderRequestServiceType } from '../../../../../models/offer_new'
import { APP_FONT } from '../../../../../constants/app_font'
import { formatPrice } from '../../../../../components/helper/helper'
import { GetServiceTotal, GetTypeName, RecommendedService } from '../service.model'
import { IconArrowLeft } from '@tabler/icons-react'
import { Group } from '@mantine/core'
import { useState } from 'react'
import { ErrorSummary } from '../../../../../components/ErrorSummary'
import { MuiInputWrapper } from '../../../../../components/MuiInputWrapper'

type SubmitShopSupplyFeesAndTaxErrors = {
  tax?: string
  shopSupplyFees?: string
}

const SubmitShopSupplyFeesAndTax = ({
  shopSupplyFees,
  handleShopSupplyFeeChange,
  tax,
  handleTaxChange,
  requestedServices,
  recServices,
  onExit,
  onSubmit,
  isValid,
  total,
  laborRate,
}: {
  shopSupplyFees: string
  handleShopSupplyFeeChange: (newShopSupplyFee: string) => void
  tax: string
  handleTaxChange: (newTax: string) => void
  requestedServices: ProviderRequestServiceType[]
  recServices: RecommendedService[]
  onExit: () => void
  onSubmit: () => void
  isValid: boolean
  total: string | undefined
  laborRate: number
}) => {
  const errors: SubmitShopSupplyFeesAndTaxErrors = {}
  let formValid = true
  if (tax === '') {
    errors.tax = 'Please enter the tax amount'
    formValid = false
  } else if (isNaN(parseFloat(tax))) {
    errors.tax = 'Tax must be a number'
    formValid = false
  }
  if (shopSupplyFees === '') {
    errors.shopSupplyFees = 'Please enter the shop supply fees amount'
    formValid = false
  } else if (isNaN(parseFloat(shopSupplyFees))) {
    errors.shopSupplyFees = 'Shop supply fees must be a number'
    formValid = false
  }
  const [submitClicked, setSubmitClicked] = useState(false)
  const visibleErrors = submitClicked ? errors : {}
  const flattenedErrors = [
    ...(visibleErrors.tax ? [{ field: 'Tax', message: visibleErrors.tax }] : []),
    ...(visibleErrors.shopSupplyFees
      ? [{ field: 'Shop supply fees', message: visibleErrors.shopSupplyFees }]
      : []),
  ]

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div
        style={{
          maxWidth: '800px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <div>
          <Typography
            onClick={onExit}
            style={{
              marginBottom: '24px',
              display: 'flex',
              gap: '8px',
              cursor: 'pointer',
              transition: 'color 0.2s ease-in-out',
              width: 'max-content',
            }}
            sx={{
              fontSize: '15px',
              fontFamily: APP_FONT,
              fontWeight: '700',
              lineHeight: '26px',
            }}
          >
            <IconArrowLeft /> Back to previous step
          </Typography>
        </div>
        <Typography
          sx={{
            fontSize: '20px',
            fontFamily: APP_FONT,
            fontWeight: '700',
            color: '#f60',
            marginBottom: '16px',
          }}
        >
          Please enter new shop supply fees and taxes:
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mb: '12px',
            fontFamily: APP_FONT,
            color: 'black',
            lineHeight: '150%',
            fontSize: '20px',
            fontWeight: '700',
          }}
        >
          Requested services
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {requestedServices
            .filter((service) => service.is_approved)
            .map((service, index) => (
              <>
                <Box
                  key={`${service.id}_${service.type}_${index}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        mt: 1,
                        lineHeight: '16px',
                        color: 'black',
                      }}
                    >
                      {service.service.parent?.parent_id
                        ? service.service.parent.name
                        : service.service.name}
                    </Typography>
                    {service.service.parent?.parent_id && (
                      <Typography
                        variant="body2"
                        style={{
                          lineHeight: '16px',
                          fontSize: '14px',
                          fontFamily: APP_FONT,
                          color: 'black',
                        }}
                      >
                        {service.service.parent?.parent_id && service.service.name}
                      </Typography>
                    )}
                  </Stack>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: 'black',
                    }}
                  >
                    {formatPrice(service.price)}
                  </Typography>
                </Box>
                <Divider key={`${service.id}_${service.type}_${index}_divider`} />
              </>
            ))}
        </div>
        {recServices.length > 0 && (
          <>
            <Typography
              variant="h6"
              sx={{
                mb: '12px',
                mt: '24px',
                fontFamily: APP_FONT,
                color: 'black',
                lineHeight: '150%',
                fontSize: '20px',
                fontWeight: '700',
              }}
            >
              Recommended services
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              {recServices.map((service, index) => (
                <>
                  <Box
                    key={`${service.id}_${service.type}_${index}`}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontFamily: APP_FONT,
                          fontWeight: '600',
                          mt: 1,
                          lineHeight: '16px',
                          color: 'black',
                        }}
                      >
                        {service.name}
                      </Typography>
                      {service.type ? (
                        <Typography
                          variant="body2"
                          style={{
                            lineHeight: '16px',
                            fontSize: '14px',
                            fontFamily: APP_FONT,
                            color: 'black',
                          }}
                        >
                          {GetTypeName(service)}
                        </Typography>
                      ) : null}
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: 'black',
                      }}
                    >
                      {formatPrice(GetServiceTotal(laborRate, service))}
                    </Typography>
                  </Box>
                  <Divider key={`${service.id}_${service.type}_${index}_divider`} />
                </>
              ))}
            </div>
          </>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            gap: '16px',
            marginTop: '24px',
            marginBottom: '26px',
          }}
        >
          <div style={{ display: 'flex', gap: '16px' }}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: 'black',
                mt: 1,
              }}
            >
              Total shop supply fees:
            </Typography>
            <MuiInputWrapper error={visibleErrors.shopSupplyFees}>
              <TextField
                error={visibleErrors.shopSupplyFees !== undefined}
                value={shopSupplyFees}
                onChange={(event) => {
                  handleShopSupplyFeeChange(event.target.value)
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { background: '#fff' },
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px', textAlign: 'right' },
                }}
                style={{ width: '235px' }}
              />
            </MuiInputWrapper>
          </div>
          <div style={{ display: 'flex', gap: '16px' }}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: 'black',
                mt: 1,
              }}
            >
              Total tax:
            </Typography>
            <MuiInputWrapper error={visibleErrors.tax}>
              <TextField
                error={visibleErrors.tax !== undefined}
                value={tax}
                onChange={(event) => {
                  handleTaxChange(event.target.value)
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { background: '#fff' },
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px', textAlign: 'right' },
                }}
                style={{ width: '235px' }}
              />
            </MuiInputWrapper>
          </div>
        </div>
        <Divider />
        <Box display="flex" gap={4} paddingTop={'16px'} paddingBottom={'16px'}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: 'black',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mb: 2,
            }}
          >
            Grand total:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '700',
              color: '#ff6600',
            }}
          >
            {total && formatPrice(total)}
          </Typography>
        </Box>
        <Group justify="center" align="center" w="100%">
          <Box flex={1} />
          <Button
            variant="contained"
            color="warning"
            disabled={!isValid || (!formValid && submitClicked)}
            onClick={() => {
              if (formValid) {
                onSubmit()
              } else {
                setSubmitClicked(true)
              }
            }}
            sx={{
              width: 'fit-content',
              borderRadius: '20px',
              textTransform: 'none',
              fontWeight: 'bold',
              paddingLeft: '32px',
              paddingRight: '32px',
              fontSize: '18px'
            }}
          >
            Submit for technician approval
          </Button>
          <Group justify="start" flex={1}>
            <ErrorSummary errors={flattenedErrors} size="lg" />
          </Group>
        </Group>
      </div>
    </div>
  )
}

export default SubmitShopSupplyFeesAndTax
