import { InputWrapper, InputWrapperProps } from '@mantine/core'
import { ReactNode } from 'react'

export const MuiInputWrapper = ({
  children,
  ...props
}: { children: ReactNode } & InputWrapperProps) => {
  return (
    <InputWrapper errorProps={{ pt: 2 }} {...props}>
      {children}
    </InputWrapper>
  )
}
